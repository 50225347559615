import styled from "styled-components";
import iconAdmiration from "../../Images/iconAdmiration.svg";
import image from "../../Images/closeImageIcon.svg";

export const Container = styled.div`
  width: 651px;
  height: 84px;
  position: fixed;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 16px;
  background-color: #353537;
  margin-top: 92px;
  z-index: 1500;
  @media screen and (min-width: 280px) and (max-width: 470px) {
    width: 95vw;
    height: auto;
  }

  @media screen and (max-width: 768px) {
    margin-top: 32px;
  }
`;

export const ContentImage = styled.div`
  width: 48px;
  height: 84px;
  display: flex;
  justify-content: center;
  align-items: start;
  background-color: #ff7f41;
  border-radius: 16px 0 0 16px;
`;

export const Image = styled.div`
  width: 33px;
  height: 33px;
  background-image: url(${iconAdmiration});
  background-repeat: no-repeat;
  background-position: center;
  margin-top: 11px;
`;
export const CloseImage = styled.div`
  background-image: url(${image});
  background-repeat: no-repeat;
  background-position: center;
  width: 16px;
  height: 16px;
  border: none;
  margin-top: 16px;
  margin-right: 16px;
`;
export const CloseButton = styled.button`
  margin-left: auto;
  background-color: transparent;
  color: #454648;
  cursor: pointer;
  border: none;
  padding: 2%;
`;


export const ContainerDes = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 16px;
  gap: 8px;
  width: 539px;
  height: 52px;
`;


export const Title = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.16px;
  color: white;
`;

export const Text = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  letter-spacing: 0.16px;
  color: white;
  margin-top:0px;
`;
