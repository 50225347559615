import React from "react";
import styled from "styled-components";
import Modal from "../Modal";
import useModal from "../../MyHooks/useModal";
import InstructionInformation from "../InstructionInformation";
import { FaChevronRight } from "../../Styles/ChoiceAppPayStyle/FaChevronRight";
import faChevronRightWhite from "../../Images/faChevronRightWhite.svg"

const Container = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  margin-top: 16px;
  background: rgba(69, 70, 72, 1);
  border-radius: 8px;
  width: 549px;
  height: 77px;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0px 16px 16px;

  @media screen and (min-width: 280px) and (max-width: 480px) {
    width: 90vw;
  }
`;

const Title = styled.span`
  width: 485px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 21px;
  font-weight: bold;
  letter-spacing: -0.3px;
  color: rgba(255, 255, 255, 1);

  @media screen and (min-width: 280px) and (max-width: 450px) {
    font-size: 15px;
    width: 150px;
  }
`;

const SubTitle = styled.span`
  width: 485px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 21px;
  font-weight: bold;
  letter-spacing: -0.3px;
  color: rgba(255, 255, 255, 1);

  @media screen and (min-width: 280px) and (max-width: 450px) {
    font-size: 15px;
    width: 150px;
  }
`;

const Button = styled.button`
  align-items: center;
  padding: 12px, 35px, 12px, 35px;
  gap: 10px;
  width: 70px;
  height: 77px;

  background-color: rgba(69, 70, 72, 1);
  border-radius: 8px;
  border: 0px solid;
  cursor: pointer;

  @media screen and (min-width: 280px) and (max-width: 450px) {
    width: 70px;
  }
`;

const ItemText = styled.div`
   flex-direction: row;
   padding: 4px;
`;

const ContainerText = styled.div`
   flex-direction: column;
`;

export function InstructionSecurityCodeV2() {
  const { isShowing, toggle } = useModal();
  return (
    <>
      <Container>
        <ContainerText>
          <ItemText>
            <Title >¿Cómo generar tu código de seguridad?</Title>
          </ItemText>
          <ItemText>
            <SubTitle>Ver paso a paso</SubTitle>
          </ItemText>
        </ContainerText>

        <ContainerText>
          <Button onClick={toggle}> <FaChevronRight image={faChevronRightWhite} /></Button>
        </ContainerText>
      </Container>
      <Modal isShowing={isShowing} hide={toggle} height="552px" width="454px" top="100px" widthM="460px" buttonClose>
        <InstructionInformation hide={toggle} />
      </Modal>
    </>
  );
}