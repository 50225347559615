import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import isotype from "../Images/Bancolombia.svg";
import guard from "../Images/vigilado.svg";
import { useMachineContext } from "../Context/contextMachine";
import { FIVE, THREE } from "../Constant/Constants";

const color = "#000000";

const StyledFooter = styled.div`
  width: 95%;
  height: 0vh;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  background-color: transparent;
  margin: auto;
`;
const Divider = styled.div`
  width: 100%;
  height: 1px;
  margin: 0 auto;
  background: #cccccc;
  border-radius: 4px;

  @media screen and (min-width: 280px) and (max-width: 480px) {
    width: 85%;
  }
`;

const IpAdress = styled.div`
  display: flex;
  margin-left: auto;
  align-items: center;
  color: ${color};
  font-family: "Open Sans";
  font-size: 12px;
  letter-spacing: -0.26px;
  line-height: 16px;

  @media screen and (min-width: 280px) and (max-width: 480px) {
    justify-content: center;
    margin: 0;
  }
`;
const IsoTypeImg = styled.div`
  margin-top: 10px;
  width: 140px;
  height: 17.98px;
  background-image: url(${isotype});
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  align-items: center;
  padding-right: 5px;

  @media screen and (min-width: 280px) and (max-width: 480px) {
    margin: 10px auto auto auto;  
  }
`;
const GuardedImg = styled.div`
  height: 14px;
  background-image: url(${guard});
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  width: 115px;
  align-items: center;
  padding-right: 5px;

  @media screen and (min-width: 280px) and (max-width: 480px) {
    width: auto;
  }
`;

const Span = styled.span`
  width: 100%;
  height: 80px;
  display: flex;

  @media screen and (min-width: 280px) and (max-width: 480px) {
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    padding-top: 0%;
    height: 100%;
  }

  @media screen and (min-width: 481px) and (max-width: 768px) {
    padding-left: 5px;
    padding-right: 5px;
  }

  @media screen and (min-width: 769px) and (max-width: 1024px) {
    padding-right: 5px;
  }
`;

const Copy = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  @media only screen and (min-width: 280px) and (max-width: 480px) {
    padding-bottom: 15px;
    padding: 10px;
    width: 100%;
  }
`;
const Adress = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-top: 10px;
  @media screen and (min-width: 280px) and (max-width: 480px) {
    margin-left: 0;
    padding-bottom: 50px;
  }
`;

const Dates = styled.div`
  display: flex;
  margin-left: auto;
  align-items: center;
  color: ${color};
  font-family: "Open Sans";
  font-size: 12px;
  letter-spacing: -0.26px;
  line-height: 16px;
`;

const options = {
  weekday: "long",
  year: "numeric",
  month: "long",
  day: "numeric",
  hour: "numeric",
  hour12: "false",
};

const capitalize = s => typeof s === "string" ? s.charAt(0).toUpperCase() + s.slice(1): "";

const hourDate = new Date().toLocaleTimeString("en").split(":");
const dayMonthDate = new Date().toLocaleTimeString("es", options).split(" ");

const weekDay = capitalize(dayMonthDate[0]);
const monthDay = dayMonthDate[1];
const month = capitalize(dayMonthDate[THREE]);
const year = dayMonthDate[FIVE];

const hour = hourDate[0];
const minutes = hourDate[1];

const temolateString = `${weekDay} ${monthDay} de ${month} de ${year} ${hour}:${minutes} ${dayMonthDate[7]}`;

function Footer({ top, topMovil }) {
  const [machine] = useMachineContext();
  return (
    <StyledFooter top={top} topMovil={topMovil}>
      <Divider />
      <Span>
        <Copy>
           <IsoTypeImg />
          <GuardedImg />
        </Copy>
        <Adress>
          <IpAdress>Dirección IP: {machine.context.currentIp.ip}</IpAdress>
          <Dates>{temolateString}</Dates>
        </Adress>
      </Span>
    </StyledFooter>
  );
}

Footer.propTypes = {
  top: PropTypes.string.isRequired,
  topMovil: PropTypes.string.isRequired,
};

export default Footer;
