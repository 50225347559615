import styled from "styled-components";


export const ButtonDisableStyle = `
  background: #D9DADD;
  border: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 218px;
  height: 50px;
  position: relative;
  border-radius: 100px;
  font-family: "Open Sans";
  font-weight: 600;
  font-size: 16px;
  outline: none;
  padding: 12px 24px;
  gap: 8px;
  cursor: no-drop;
  color: #97999B;

  @media screen and (min-width: 280px) and (max-width: 480px) {
    width: 150px;
  }

  @media screen and (min-width: 481px) and (max-width: 961px) {
    width: 180px;
  }

  @media screen and (min-width: 320px) and (max-width: 900px) and (orientation: landscape) and (min-aspect-ratio: 13/9) {
    width: 150px;
    margin-top: 25px;
  }

  @media screen and (min-width: 920px) and (max-width: 974px) and (orientation: landscape) {
    width: 180px;
  }
`;

export const ButtonEnableStyle = `
  background: #FDDA24;
  border: 0;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 218px;
  height: 50px;
  position: relative;
  border-radius: 100px;
  font-family: "Open Sans";
  font-weight: 600;
  font-size: 16px;
  outline: none;
  padding: 12px 24px;
  gap: 8px;
  color: #2C2A29;

  @media screen and (min-width: 280px) and (max-width: 480px) {
    width: 150px;
  }

  @media screen and (min-width: 481px) and (max-width: 961px) {
    width: 180px;
  }

  @media screen and (min-width: 320px) and (max-width: 900px) and (orientation: landscape) and (min-aspect-ratio: 13/9) {
    width: 150px;
    margin-top: 25px;
  }

  @media screen and (min-width: 920px) and (max-width: 974px) and (orientation: landscape) {
    width: 180px;
  }
`;

export const ButtonBackStyle = `
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  gap: 8px;
  width: 218px;
  height: 50px;
  border: 1px solid #2C2A29;
  border-radius: 100px;
  background: #FFFFFF;
  cursor: pointer;

  @media screen and (min-width: 280px) and (max-width: 480px) {
    width: 150px;
  }

  @media screen and (min-width: 481px) and (max-width: 961px) {
    width: 180px;
  }
  
  @media screen and (min-width: 320px) and (max-width: 900px) and (orientation: landscape) and (min-aspect-ratio: 13/9) {
    width: 150px;
    margin-top: 25px;
  }
  
  @media screen and (min-width: 920px) and (max-width: 974px) and (orientation: landscape) {
    width: 180px;
  }
`;

export const DefaultLabel = `
  width: 66px;
  height: 26px;
  justify-content: center;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  color: black;
  display: flex;
  align-items: center;
  text-align: center;`

  export const OnlyButtonDisableStyle = `
  background: #D9DADD;
  border: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 460px;
  height: 50px;
  position: relative;
  border-radius: 100px;
  font-family: "Open Sans";
  font-weight: 600;
  font-size: 16px;
  outline: none;
  padding: 12px 24px;
  gap: 8px;
  cursor: no-drop;
  color: #97999B;

  @media screen and (min-width: 280px) and (max-width: 480px) {
    width: 260px;
  }

  @media screen and (min-width: 481px) and (max-width: 700px) {
    width: 350px;
  }

  @media screen and (min-width: 701px) and (max-width: 961px) {
    width: 420px;
  }

  @media screen and (min-width: 320px) and (max-width: 700px) and (orientation: landscape) and (min-aspect-ratio: 13/9) {
    width: 300px;
  }

  @media screen and (min-width: 701px) and (max-width: 960px) and (orientation: landscape) and (min-aspect-ratio: 13/9) {
    width: 360px;
  }

  @media screen and (min-width: 962px) and (max-width: 974px) and (orientation: landscape) {
    width: 300px;
  }
`;

export const OnlyButtonEnableStyle = `
  background: #FDDA24;
  border: 0;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 460px;
  height: 50px;
  position: relative;
  border-radius: 100px;
  font-family: "Open Sans";
  font-weight: 600;
  font-size: 16px;
  outline: none;
  padding: 12px 24px;
  gap: 8px;
  color: #2C2A29;

  @media screen and (min-width: 280px) and (max-width: 480px) {
    width: 260px;
  }

  @media screen and (min-width: 481px) and (max-width: 700px) {
    width: 350px;
  }

  @media screen and (min-width: 701px) and (max-width: 961px) {
    width: 420px;
  }

  @media screen and (min-width: 320px) and (max-width: 700px) and (orientation: landscape) and (min-aspect-ratio: 13/9) {
    width: 300px;
  }

  @media screen and (min-width: 701px) and (max-width: 960px) and (orientation: landscape) and (min-aspect-ratio: 13/9) {
    width: 360px;
  }

  @media screen and (min-width: 962px) and (max-width: 974px) and (orientation: landscape) {
    width: 300px;
  }
`;

export const Label = styled.label`
   ${props => props.labelStyle || DefaultLabel}
`;

export const ButtonTemplateStyle = styled.button`
     ${props => props.customStyle }
`;