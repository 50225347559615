import React from "react";
import PropTypes from "prop-types";
import "../AppAtHandFormStyle/styleInput.css"
import {InputContainer, Label, StyledInput} from "./InputStyle"

function Input({
  type,
  name,
  autoCorrect,
  autoComplete,
  valid,
  error,
  label,
  placeholder,
  top,
  value,
  disabled,
  maxLength,
}) {
  return (
    <>
      <InputContainer top={top}>
        <StyledInput
          type={type}
          name={name}
          autoCorrect={autoCorrect}
          autoComplete={autoComplete}
          valid={valid}
          error={error}
          placeholder={placeholder}
          value={value}
          disabled={disabled}
          maxLength={maxLength}
        />
        <Label>{label}</Label>
      </InputContainer>
    </>
  );
}
Input.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  autoCorrect: PropTypes.string.isRequired,
  autoComplete: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  top: PropTypes.string.isRequired,
  maxLength: PropTypes.number,
};
export default Input;
