import ButtonTemplate from "../../ButtonTemplate/ButtonTemplate";
import { ButtonGroup, ButtonCancelStyle, LabelStyle, ButtonContinueStyle } from "./ButtonGroupPopUpGoOutStyle";
import { cancelTransaction ,cancelTransactionHome } from "../../../Utilities/GeneralFunctions"
import { EFA008, EFA007 } from "../../../Constant/Constants";

export function ButtonGroupPopUpGoOut({  setShowPopUpGoOut, machine, isButtonBack, isV2 }) {
    const efa = isButtonBack ? EFA008: EFA007

    const showPopUp = () => setShowPopUpGoOut(false)
    
    const executeCancelTransaction = () => isV2 ? cancelTransaction(machine, efa) : cancelTransactionHome()


    return (
        <ButtonGroup>
            <ButtonTemplate
                customStyle={ButtonCancelStyle}
                title={"No, deseo continuar"}
                fuctionButton={showPopUp}
                labelStyle={LabelStyle}
            />
            <ButtonTemplate
                customStyle={ButtonContinueStyle}
                fuctionButton={executeCancelTransaction}
                title={"Si, deseo salir"}
                labelStyle={LabelStyle}
            />
        </ButtonGroup>
    );
}
