import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import step1 from "../Images/paso1.svg";
import step2 from "../Images/paso2.svg";
import step3 from "../Images/paso3.svg";
import Button from "./Button";

const Container = styled.div`
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Content = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  font-family: "Open Sans";
  font-size: 15px;
  color: #454648;
`;

const Title = styled.div`
  position: relative;
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 26px;
  letter-spacing: -0.0045em;
  text-align: center;
  top: 15px;
`;
const SubTitle = styled.div`
  position: relative;
  display: flex;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.003em;
  top: 25px;
`;
const ContainerItems = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  top: 50px;
`;

const Items = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
`;
const ItemsTitle = styled.div`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: -0.2px;
`;

const Images = styled.div`
  background-image: url(${step1});
  background-repeat: no-repeat;
  background-position: center;
  width: 215px;
  height: 150px;
`;
const Images2 = styled.div`
  background-image: url(${step2});
  background-repeat: no-repeat;
  background-position: center;
  width: 215px;
  height: 200px;
`;
const Images3 = styled.div`
  background-image: url(${step3});
  background-repeat: no-repeat;
  background-position: center;
  width: 215px;
  height: 200px;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  position: relative;
  top: 60px;
`;

function RememberUser({ hide }) {
  return (
    <Container>
      <Content>
        <Title>¿Olvidaste tu usuario?</Title>
        <SubTitle>Estos son los pasos para recordar tu usuario:</SubTitle>
        <ContainerItems>
          <ItemsTitle>1.Ingresa a la Sucursal Virtual Personas</ItemsTitle>
          <Items> <Images /> </Items>
          <ItemsTitle>2.Ingresa a la opción ¿Olvidó su usuario?</ItemsTitle>
          <Items> <Images2 /> </Items>
          <ItemsTitle>
            3. Ingresa tus datos para confirmar tu identidad
          </ItemsTitle>
          <Items> <Images3 /> </Items>
        </ContainerItems>
        <ButtonGroup>
          <Button
            width="184px"
            height="38px"
            widthS="55%"
            top="15%"
            typeButton="primary"
            onClick={hide}
          >
            Cerrar
          </Button>
        </ButtonGroup>
      </Content>
    </Container>
  );
}

RememberUser.propTypes = {
  hide: PropTypes.func.isRequired,
};

export default RememberUser;
