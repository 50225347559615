import React from "react";
import PropTypes from "prop-types";
import { Title , SubTitle} from "../Styles/TitleHeaderStyle/Title";

function TitleHeader({
  title,
  widthTitle,
  heightTitle,
  topTittle,
  subTitle,
  topSubTittle
}) {
  return (
    <>
      <Title widthTitle={widthTitle} heightTitle={heightTitle} topTittle={topTittle}>{title}</Title>
      <SubTitle topSubTittle={topSubTittle}>{subTitle}</SubTitle>
    </>
  );
}

TitleHeader.propTypes = {
  title: PropTypes.string.isRequired,
  widthTitle: PropTypes.string.isRequired,
  heightTitle: PropTypes.string.isRequired,
  topTittle: PropTypes.string.isRequired,
};

export default TitleHeader;
