import {
  TABLETDEVICEREGEX,
  TABLET,
  MOBILE,
  PC,
} from "../Constant/Constants";
import { isMobile } from 'react-device-detect';
import { Cookies } from 'react-cookie';

const getUrlParam = param => {
  let result = null;
  let tmp = [];
  const { hash } = document.location;
  document.location.search
    .substring(1)
    .split("&")
    .forEach(item => {
      tmp = item.split("=");
      if (param === tmp[0]) {
        if (hash !== "" && param === "redirect_uri") {
          result = decodeURIComponent(tmp[1]) + hash;
        } else {
          result = decodeURIComponent(tmp[1]);
        }
      }
    });
  return result;
};
export default getUrlParam;

export const generateUuid = () => window.crypto.randomUUID();

export const getTrace = context => context.trace !== null && context.trace !== "" ? `&trace=${context.trace}` : ""

export const getDevice = async () => {
  let device = PC
  if (TABLETDEVICEREGEX.test(window.navigator.userAgent)) {
    device = TABLET;
  } else if (isMobile) {
    device = MOBILE;
  }
  return device
};

export const addValueCookie = async (nameCookie, expiresTime, valueCookie) => {
  const cookies = new Cookies();
  const d = new Date();
  d.setTime(d.getTime() + expiresTime);
  cookies.set(nameCookie, valueCookie, {
    path: '/',
    expires: d,
    secure: true,
    sameSite: "strict"
  })
}

export const getValueCookie = async nameCookie => {
  const cookies = new Cookies();
  return cookies.get(nameCookie)
}

export const executeDateTimeLocal = () => {
  try {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const timeZoneDefault = "America/Bogota";
    const timeZoneValidate = timeZone !== undefined ? timeZone : timeZoneDefault
    const locale = Intl.DateTimeFormat().resolvedOptions().locale

    const optionsDate = {
      weekday: "long",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      timeZone: timeZoneValidate
    };

    const optionsTime = {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: "true",
      timeZone: timeZoneValidate
    };

    const hour = new Date().toLocaleTimeString(locale, optionsTime).replace("a.", "").replace("m.", "").trim();
    const dateNow = new Date().toLocaleTimeString(locale, optionsDate).split(",");
    const dateSplit = dateNow[1].split("/")
    const formateDate = `${dateSplit[2]}-${dateSplit[1]}-${dateSplit[0].trim()}`
    return `${formateDate} ${hour}`
  } catch (e) {
    return ""
  }
}

export const executeDateTimeUtc = () => {
  try {
    const dateTime = new Date().toISOString().replace("T", " ")
    return dateTime;
  } catch (e) {
    return ""
  }
}