import React, { useEffect } from "react";
import { SecondaryContainer } from "../Styles/UnavailabilityScreenStyle/SecondaryContainer";
import PrincipalContainer from "../Styles/UnavailabilityScreenStyle/PrincipalContainer";
import { useMachineContext } from "../Context/contextMachine";
import Footer from "./Footer";
import { LogoBank } from "../Styles/UserFormStyle/LogoBank"
import { Image } from "../Styles/UnavailabilityScreenStyle/Image"
import { Title } from "../Styles/UnavailabilityScreenStyle/Title"
import { Description } from "../Styles/UnavailabilityScreenStyle/Description"
import { Button } from "../Styles/UnavailabilityScreenStyle/Button"
import {
  EFA010,
  authDynamicKeyPath
} from "../Constant/Constants";
import { errorSystem, flowNameDynamicKeyFirstFactor } from "../Constant/ConstantsDataLayer"
import { getTrace } from '../Utilities/httpUtils'
import { contextSwitchHandlerBiocatch } from "../Utilities/GeneralFunctions"
import { errorModalUnavailability } from "../HandlerComponents/HandlerDatalayers/HandlerDatalayers"

export function Unavailability() {
  const [machine] = useMachineContext();

  useEffect(() => {
    contextSwitchHandlerBiocatch("UNAVAILABILITY_SCREEN_V2")
  }, []);

  useEffect(() => {
    const errorTag = document.getElementById("errorAlert");
    if (errorTag) {
      errorTag.scrollIntoView(true);
      window.scrollTo(0, 0);
    }
  }, []);

  const redirectionUri = e => {
    e.preventDefault();
    window.location.replace(machine.context.redirectUri + EFA010 + getTrace(machine.context));
  };

  useEffect(() => {
    try {
      const url = machine.context.startUrl
      if (url && url !== "") {
        if (url.includes(authDynamicKeyPath) || url.includes(authDynamicKeyPath.substring(0, authDynamicKeyPath.length - 1))) {
          errorModalUnavailability(flowNameDynamicKeyFirstFactor)
        }
      }
      return `success datalayer `
    } catch (e) {
      return `error datalayer ${e}`
    }
  }, []);

  return (
    <PrincipalContainer>
      <LogoBank id="errorAlert" show={true} />
      <SecondaryContainer>
        <Image />
        <Title>{errorSystem}</Title>
        <Description>{"Nuestro equipo esta trabajando para restaurar la conexión. Inténtalo nuevamente más tarde."}</Description>
        <Button onClick={redirectionUri}>Volver</Button>
      </SecondaryContainer>
      <Footer top="0" topMovil="560px" />
    </PrincipalContainer>
  );
}