import AlertMessage from "../../Components/AlertMessage";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { clickContinueFormUserDatalayer, clickContinueFormUserDynamicKeyDatalayer, clickContinueFormAppPersonDatalayer } from "../HandlerDatalayers/HandlerDatalayers"
import { referrerMatchAppAuth } from "../../Constant/Constants"
import { flowNameUser, flowNameDynamicKey } from "../../Constant/ConstantsDataLayer"

export function HandlerUserForm({ machine, showAlert, close, urlRedirect }) {
    const isMatchCredentialsError = ["sendCredentials.error", "validateUserDynamicKey.errorIncorrectData", "validateAlphanumericKeyAndTokenSf.errorIncorrectData"]
    const isMatchForm = ["sendCredentials.continueFlow", "validateUserDynamicKey.continueFlow", "validateAlphanumericKeyAndTokenSf.continueFlow"]
    const history = useHistory();
    const [isDataIncorrect, setIsDataIncorrect] = useState(false);
    const prompt = machine.context.prompt 
    const [isFlowUsCd, setIsFlowUsCd] = useState(prompt != null && prompt.includes("PN") && prompt.includes("CD"));
    
    useEffect(() => {
        setIsFlowUsCd(prompt != null && prompt.includes("PN") && prompt.includes("CD"))
    }, [machine]);

    const handleMatchForm = () => {
        history.push(urlRedirect);
        executeDatalayers()
    };

    const handleMatchCredentialsError = () => {
        const hasIncorrectData = showAlert && isMatchCredentialsError.some(machine.matches);
        setIsDataIncorrect(hasIncorrectData);
    };

    useEffect(() => {
        if (isMatchForm.some(machine.matches)) {
            handleMatchForm();
        } else {
            handleMatchCredentialsError();
        }
    }, [machine, showAlert]);

    const executeDatalayers = () => {
        if (referrerMatchAppAuth !== null) {
            clickContinueFormAppPersonDatalayer()
        } else {
            isFlowUsCd ? clickContinueFormUserDynamicKeyDatalayer() : clickContinueFormUserDatalayer()
        }
    }

    return (
        <>
            {isDataIncorrect && (
                <AlertMessage close={close} title="Datos incorrectos" message="Los datos ingresados no coinciden, inténtalo nuevamente." flowName={isFlowUsCd ? flowNameDynamicKey : flowNameUser} />
            )}
        </>
    )
}