import ButtonTemplate from "../../ButtonTemplate/ButtonTemplate";
import {
    ButtonEnableStyle,
    ButtonDisableStyle,
    ButtonBackStyle,
} from "../../ButtonTemplate/ButtonTemplateStyle";
import { ButtonGroup } from "../../../Styles/UserFormStyle/ButtonGroup"

export function ButtonGroupUserDynamicKeyForm({ errors, isValid, isMatch, machine, setShowPopUpGoOut, setIsButtonBack, isHeaderTemplate }) {
    const showPopUp = async () => {
        setIsButtonBack(true)
        setShowPopUpGoOut(true)
    };
    return (
        <>
            {isHeaderTemplate &&
                <ButtonGroup>
                    <ButtonTemplate
                        customStyle={ButtonBackStyle}
                        type="button"
                        title={"Volver"}
                        fuctionButton={showPopUp}
                        id={"btn-volver-user"}
                    />
                    <ButtonTemplate
                        customStyle={
                            !errors.user && isValid && !isMatch.some(machine.matches)
                                ? ButtonEnableStyle
                                : ButtonDisableStyle
                        }
                        type="submit"
                        title={"Continuar"}
                        id={"btn-continuar-user"}
                    />
                </ButtonGroup>
            }
        </>
    );
}