import axios from "axios";
import getUrlParam, { getDevice } from "../Utilities/httpUtils";
import config from "../config";
import{ contentType, USER_AGENT }  from "../Constant/Constants";

const URLCUSTOMERFLOW = config.url.URLCUSTOMERFLOW;

export const customerFlow = async (context, operation, step) => {
    const trace = context.trace === "" ? getUrlParam("trace") : context.trace
    if (trace !== null && trace !== "") {
        const request = {
            trace: trace,
            consumer: context.idClient === "" ? getUrlParam("client_id") : context.idClient,
            operation: operation,
            step: step,
            device: await getDevice(),
            useragent: window.navigator.userAgent.slice(0, USER_AGENT)
        }
        await axios.post(URLCUSTOMERFLOW, request, { contentType })
    }
}