import Modal from "../../Components/Modal";
import ErrorDynamicKey from "../../Components/ErrorDynamicKey";
import RedirectionMessage from "../../Components/RedirectionMessage";

export function HandlerDynamicKeyForm({ machine, errorForm, showErrorDynamicKey, codeError, redirect, history }) {
    const isMatchUnavailability = ["sendCredentials.errorForm", "sendCredentials.errorPrepareDynamicKey"]
    return (
        <div>
            {(isMatchUnavailability.some(machine.matches) || errorForm) &&
                (() => {
                    history.push("/unavailability");
                })()}

            {showErrorDynamicKey && (
                <Modal
                    isShowing
                    height="720px"
                    topM="0%"
                    top="0%"
                    buttonClose={false}
                    width="430px"
                    widthM="340px"
                >
                    <ErrorDynamicKey codeError={codeError} />
                </Modal>
            )}
            {redirect === true && <RedirectionMessage />}
        </div>
    )
}