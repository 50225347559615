import styled from "styled-components";
import closeIcon from "../../Images/closeIcon.svg";
import errorImageScoreRecaptcha from "../../Images/errorImageScoreRecaptcha.svg";

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(19.028px);
`;

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
`;
export const Header = styled.div`
  width: 100%;  
  position: absolute;
  display: flex;
  justify-content: flex-end;
`;

export const CloseButton = styled.button`
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1;
  color: #454648;
  background-color: transparent;
  cursor: pointer;
  border: none;
  outline: none;
  align-self: flex-end;
  margin-top: 20px;
    margin-right: 20px;
`;

export const Container = styled.div`
  display: flex;
  margin: 2rem auto;
  position: relative;
  flex-direction: column;
  align-content: center;
  width: 600px;
  height: 296px;
  top: 150px;
  background: #FFFFFF;
  box-shadow: 0px 10px 16px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  @media screen and (min-width: 280px) and (max-width: 480px) {
    width: 340px;
    height: ${props => props.heightM};
  }

  @media screen and (min-width: 280px) and (max-width: 480px) {
    width: 340px;
    height: ${props => props.heightM};
  }

  @media screen and (min-width: 481px) and (max-width: 600px) {
    width: 480px;
    height: ${props => props.heightM};
  }
`;

export const ImageClose = styled.div`
  background-image: url(${closeIcon});
  background-repeat: no-repeat;
  background-position: center;
  width: 16px;
  height: 16px;
  padding: 5px;
`;

export const ImageError = styled.div`
  margin-top: 32px;
  background-image: url(${errorImageScoreRecaptcha});
  display: flex;
  width: 48px;
  height: 80px;
`;

export const CotainerImageError = styled.div`
  display: flex;
  justify-content: center;
  left: 200px;
  
  @media screen and (min-width: 280px) and (max-width: 480px) {
    left: 0px;
  }
`;


export const ContainerDes = styled.div`
  display: flex;
  flex-direction: column;
  order: 3;
  flex-grow: 0;
  justify-content: center;
  align-items: center;
`;

export const Title = styled.h4`
  color: #000000;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 26px;
  text-align: center;
  letter-spacing: -0.45px;
  margin-bottom: 0px;
  margin-top: 0px;
`;

export const Description = styled.div`
  color: #000000;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 60px;
  text-align: center;
  letter-spacing: -0.3px;
`;

export const TitleMargin = styled.h4`
  color: #000000;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 26px;
  text-align: center;
  letter-spacing: -0.45px;
  margin: 1rem 0;
`;

export const DescriptionTiny = styled.div`
  color: #000000;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  letter-spacing: -0.3px;
  padding-bottom: 2em;
  margin: 0 1em;
`;

export const Button = styled.button`
  background: #FDDA24;
  border: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 201px;
  height: 38px;
  position: relative;
  border-radius: 100px;
  font-family: "Open Sans";
  font-weight: 600;
  font-size: 16px;
  outline: none;
  top: 5px;
  padding: 9px 38px;
  gap: 8px;
`;
