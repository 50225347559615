import React, { createContext, useState, useContext } from "react";
import PropTypes from "prop-types";
import {
  TABLETDEVICEREGEX,
  TABLET,
  MOBILE,
  PC,
} from "../Constant/Constants";
import { isMobile } from 'react-device-detect';

export const FuaContext = createContext(null);

export const Provider = ({ children }) => {
  const [username, setUsername] = useState(null);
  const [deviceType, setDeviceType] = useState(null);
  const [modulus, setModulus] = useState(null);
  const [document, setDocument] = useState(null);
  const [celphoneNumber, setCelphoneNumber] = useState(null);
  const [typeDocument, setTypeDocument] = useState(null);

  const setDevice = () => {
    if (TABLETDEVICEREGEX.test(window.navigator.userAgent)) {
      setDeviceType(TABLET);
    } else if (isMobile) {
      setDeviceType(MOBILE);
    } else {
      setDeviceType(PC);
    }
  };

  const values = {
    username,
    setUsername,
    deviceType,
    setDevice,
    modulus,
    setModulus,
    document,
    setDocument,
    celphoneNumber,
    setCelphoneNumber,
    typeDocument,
    setTypeDocument,
  };
  return <FuaContext.Provider value={values}>{children}</FuaContext.Provider>;
};

Provider.propTypes = {
  children: PropTypes.element.isRequired,
};

export const UseFua = () => useContext(FuaContext);
