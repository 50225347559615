import ButtonTemplate from "../../ButtonTemplate/ButtonTemplate";
import {
    ButtonEnableStyle,
    ButtonDisableStyle,
    ButtonBackStyle,
} from "../../ButtonTemplate/ButtonTemplateStyle";
import { ButtonGroupFirstFactor } from "../../../Styles/DynamicKeyFirstFactor/DynamicKeyFirstFactorStyle";
import { back } from "../../../Utilities/GeneralFunctions"

export function ButtonGroupUserDynamickKey({  validateForm, conditionReCaptchaToken }) {
    return (
        <>
            <ButtonGroupFirstFactor>
                <ButtonTemplate
                    customStyle={ButtonBackStyle}
                    type="button"
                    title={"Volver"}
                    fuctionButton={back}
                />
                <ButtonTemplate
                    customStyle={conditionReCaptchaToken ? ButtonEnableStyle : ButtonDisableStyle}
                    type="submit"
                    fuctionButton={validateForm}
                    title={"Continuar"}
                    disabled={!conditionReCaptchaToken}
                />
            </ButtonGroupFirstFactor>
        </>
    );
}