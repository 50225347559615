import React from "react";
import { createPortal } from "react-dom";
import { Overlay, Wrapper, Header, CloseButton, Container, ImageClose, IconImage, CotainerImageError, ContainerDes, Title, Description, Button, Label, AdditionalDescription } from "./ModalErrorTemplateStyle"

function ModalErrorTemplate({
    fuctionButtonClose,
    isShowCloseButton,
    iconImageSvg,
    title,
    description,
    additionalDescription,
    functionButtonDescription,
    buttonText,
    heightM,
    height,
    heightIcon,
    widthM,
    showAdditionalbotones,
    additionalButtons,
    hiddenAditionalDescription
}) {
    return (createPortal(
        <>
            <Overlay />
            <Wrapper>
                <Container heightM={heightM} height={height} widthM={widthM}>
                    <Header>
                        {isShowCloseButton && (
                            <CloseButton type="button" onClick={fuctionButtonClose}>
                                <ImageClose />
                            </CloseButton>
                        )}
                    </Header>
                    <CotainerImageError>
                        <IconImage iconoImage={iconImageSvg} heightIcon={heightIcon}></IconImage>
                    </CotainerImageError>
                    <ContainerDes>
                        <Title>{title}</Title>
                        <Description>{description}</Description>
                        <AdditionalDescription hidden={hiddenAditionalDescription}>{additionalDescription}</AdditionalDescription>
                        {showAdditionalbotones === true &&  additionalButtons}
                        {showAdditionalbotones !== true && <Button onClick={functionButtonDescription} hiddenAditionalDescription={hiddenAditionalDescription}><Label>{buttonText}</Label></Button>} 
                    </ContainerDes>
                </Container>
            </Wrapper>
        </>,
        document.body
    ))
}
export default ModalErrorTemplate;