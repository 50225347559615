import styled from "styled-components";

export const InputGroup = styled.div`
width: 80%;
display: flex;
justify-content: center;
margin: 0.5em auto 3em auto;

@media screen and (min-width: 280px) and (max-width: 480px) {
  position: relative;
}

@media screen and (min-width: 280px) and (max-width: 961px) and (orientation: landscape) {
  width: 20%;
}

@media screen and (min-width: 320px) and (max-width: 850px) and (orientation: landscape) and (min-aspect-ratio: 13/9) {
  width: 20%;
}

@media screen and (width: 320px) and (height: 568px) and (orientation: portrait){
  top: -8px;
  width: 5%;
}

@media screen and (width: 480px) and (height: 320px) and (orientation: landscape){
  top: -30px;
  width: 5%;
}
`;
