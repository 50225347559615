import styled from "styled-components";

export const Title = styled.div`
display: flex;
justify-content: center;
text-align: center;

width: ${props => props.widthTitle};
height: ${props => props.heightTitle};

font-family: Nunito;
font-style: normal;
font-weight: 150;
font-size: 28px;

letter-spacing: -0.6px;
color: #292929;
line-height: 30px;

@media screen and (min-width: 280px) and (max-width: 480px) {
  width: 100%;
  line-height: 26px;
  font-size: 22px;
}

@media screen and (min-width: 481px) and (max-width: 961px) {
  width: 90%;
  font-size: 24px;
}

@media screen and (min-width: 961px) and (max-width: 1024px) {
  width: 90%;
}

@media screen and (min-width: 320px) and (max-width: 767px) and (orientation: landscape) and (min-aspect-ratio: 13/9) {
  width: 90%;
}

@media screen and (width: 854px) and (height: 384px) and (orientation: landscape){
  font-size: 22px;
}

@media screen and (min-width: 567px) and (max-width: 575px) and (orientation: landscape) {
  font-size: 15px;
}

@media screen and (min-width: 576px) and (max-width: 638px) and (orientation: landscape) {
  font-size: 22px;
}

@media screen and (min-width: 640px) and (max-width: 729px) and (orientation: landscape) {
  font-size: 22px;
}

@media screen and (min-width: 730px) and (max-width: 851px) and (orientation: landscape) {
  font-size: 22px;
}

@media screen and (min-width: 962px) and (max-width: 974px) and (orientation: landscape) {
  font-size: 22px;
}

@media screen and (min-width: 975px) and (max-width: 981px) and (orientation: landscape) {
  font-size: 22px;
}

@media screen and (min-width: 982px) and (max-width: 1026px) and (orientation: landscape) {
  font-size: 22px;
}

@media screen and (min-width: 1038px) and (max-width: 10042px) and (orientation: landscape) {
  font-size: 22px;
}

@media screen and (min-width: 1043px) and (max-width: 1060px) and (orientation: landscape) {
  font-size: 22px;
}

@media screen and (min-width: 1078px) and (max-width: 1183px) and (orientation: landscape) {
  font-size: 20px;
}

@media screen and (min-width: 1210px) and (max-width: 1229px) and (orientation: landscape) {
  top: 110px;
}

@media screen and (min-width: 1231px) and (max-width: 1238px) and (orientation: landscape) {
  font-size: 18px;
}

@media screen and (min-width: 1304px) and (max-width: 1323px) and (orientation: landscape) {
  font-size: 20px;
}

@media screen and (min-width: 1324px) and (max-width: 1325px) and (orientation: landscape) {
  font-size: 20px;
}

@media screen and (min-width: 1326px) and (max-width: 1384px) and (orientation: landscape) {
  font-size: 22px;
}

@media screen and (min-width: 1847px) and (max-width: 1900px) and (orientation: landscape) {
  font-size: 22px;
}

@media screen and (min-width: 2063px) and (max-width: 6160px) and (orientation: landscape) {
  font-size: 22px;
}
`;


export const SubTitle = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 0 auto 8px auto;
  width: 358px;
  height: 22px;
  top: ${props => props.topSubTittle};


  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;

  letter-spacing: -0.3px;
  color: #2C2A29;

  @media screen and (min-width: 280px) and (max-width: 480px) {
    top: 150px;
    font-size: 16px;
  }

  @media screen and (min-width: 481px) and (max-width: 961px) {
   
  }

  @media screen and (min-width: 961px) and (max-width: 1024px) {
    
  }

  @media screen and (min-width: 320px) and (max-width: 767px) and (orientation: landscape) and (min-aspect-ratio: 13/9) {
    top: 150px;
    
  }

  @media screen and (min-width: 481px) and (max-width: 961px) and (orientation: landscape) {
    top: 190px;
  }
`;
