import styled from "styled-components";

export const TextWhichOption = styled.div`
  font-family: "Open Sans";
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: rgba(44, 42, 41, 1);
  text-decoration: underline;

  @media screen and (min-width: 280px) and (max-width: 500px) {
    font-size: 13.84px;
    line-height: 19.78px;
  }
`;
