import styled from "styled-components";

export const Title = styled.div`
  display: flex;
  font-size: 24px;
  font-weight: bold;
  color: #000000;
  font-family: "Nunito";
  text-align: center;
  margin-top: 15px;
  margin-bottom: 15px;
  position: relative;
`;