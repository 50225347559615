import styled from "styled-components";

export const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  padding-bottom: 2em;
`;

export const ButtonCancelStyle = `
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  gap: 8px;
  width: 221px;
  height: 39px;
  border: 1px solid #2C2A29;
  border-radius: 100px;
  background: #FFFFFF;
  cursor: pointer;

  @media screen and (min-width: 280px) and (max-width: 480px) {
    width: 180px;
  }

  @media screen and (min-width: 481px) and (max-width: 750px) {
    width: 180px;
  }
  

`;

export const LabelStyle = `
  width: 170px;
  height: 21px;
  justify-content: center;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;

  display: flex;
  align-items: center;
  text-align: center;

  @media screen and (min-width: 280px) and (max-width: 480px) {
    font-size: 12px;
  }

  @media screen and (min-width: 481px) and (max-width: 700px) {
    font-size: 12px;
  }

  @media screen and (min-width: 320px) and (max-width: 900px) and (orientation: landscape) and (min-aspect-ratio: 13/9) {
    font-size: 12px;
  }

  @media screen and (min-width: 920px) and (max-width: 974px) and (orientation: landscape) {
    font-size: 12px;
  }
`;

export const ButtonContinueStyle = `
  background: #FDDA24;
  border: 0;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 183px;
  height: 38px;
  position: relative;
  border-radius: 100px;
  font-family: "Open Sans";
  font-weight: 600;
  font-size: 16px;
  outline: none;
  padding: 12px 24px;
  gap: 8px;
  cursor: pointer;

  cursor: pointer;
  color: #2C2A29;

  @media screen and (min-width: 280px) and (max-width: 480px) {
    width: 150px;
  }

  @media screen and (min-width: 481px) and (max-width: 750px) {
    width: 160px;
  }
`;