import styled from "styled-components";

export const ButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  top: 150px;
  className: ${props => props.className}
  align-items: center;
`;
