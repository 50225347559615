import styled from "styled-components";

export const GroupDescriptionText = styled.div`
  flex-direction: column;
  align-items: start;
  className: col-sm col-md-auto;
  text-align: initial;
  padding: 0px 0px 0px 10px;

  @media screen and (min-width: 280px) and (max-width: 480px) {
    top: 150px;
  }

  @media screen and (min-width: 481px) and (max-width: 961px) {
    top: 150px;
  }

  @media screen and (min-width: 320px) and (max-width: 1366px) and (orientation: landscape) and (min-aspect-ratio: 13/9) {
    top: 190px;
  }
`;
