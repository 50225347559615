import styled from "styled-components";

export const GroupDescription = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 458px;

  /*tablet*/
  @media screen and (min-width: 768px) and (max-width: 991px) {
    width: 472px;
  }

  /*mobile*/
  @media screen and (min-width: 576px) and (max-width: 767px) {
    width: 358px;
  }

  @media screen and (min-width: 321px) and (max-width: 575px) {
    width: 343px;
  }

  @media screen and (min-width: 0px) and (max-width: 320px) {
    width: 300px;
  }
`;
