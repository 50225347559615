const urlMka = "https://tu360compras.grupobancolombia.com/login/FUA"
const urlMke = "https://loginoportunidadestu360.grupobancolombia.com/login/fua"
const urlMceQa = "https://movilidad-qa.apps.ambientesbc.com/movilidad/home"
const urlSimPvi = "https://www.bancolombia.com/personas/creditos/vivienda/credito-hipotecario-para-comprar-vivienda"
const urlNet = "https://www.bancolombia.com/tu360/negocios?utm_source=link&utm_medium=home&utm_campaign=redireccionamiento-megamenuhome%E2%80%8B"
const urlMsc = "https://nam10.safelinks.protection.outlook.com/?url=https%3A%2F%2Fwww.bancolombia.com%2Fpersonas%2Fsolicitud-de-productos%2Fcuentas%2Fcuentas-ahorros%3F_ga%3D2.149964854.1241159576.1673452884-942641708.1644596727%26_gac%3D1.46027094.1669838683.Cj0KCQiAm5ycBhCXARIsAPldzoWbZae52cDUOCGRkEAU4lR6xTpM6ZnH7sDGngBEGj3t-VlO7Eo8SM4aAveqEALw_wcB&data=05%7C01%7Cluisagar%40bancolombia.com.co%7Cad969efa5da446960b7808daf4db9e22%7Cb5e244bdc492495b8b1061bfd453e423%7C0%7C0%7C638091521336167393%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C3000%7C%7C%7C&sdata=KPElUHdXJMGQa6Ii8ALsipZRXmmvRL1M%2FmEFkGT4BnU%3D&reserved=0"
const urlGmn = "https://www.bancolombia.com/educacion-financiera/criptomonedas"
const urlAtd = "https://www.bancolombia.com/personas/tarjetas-debito/activar"
const urlPcb = "https://www.bancolombia.com/personas"

export const listUrlRedirect = [
    { clientId: "MCE", urlRedirectDev: urlMceQa, urlRedirectQa: urlMceQa, urlRedirectPdn: "https://tu360.grupobancolombia.com/movilidad/home" },
    { clientId: "MKA", urlRedirectDev: urlMka, urlRedirectQa: urlMka, urlRedirectPdn: urlMka },
    { clientId: "MKE", urlRedirectDev: urlMke, urlRedirectQa: urlMke, urlRedirectPdn: urlMke },
    { clientId: "PCE", urlRedirectDev: "https://habitat-personas-dev.apps.ambientesbc.com/inmobiliario/home", urlRedirectQa: "https://habitat-personas-qa.apps.ambientesbc.com/inmobiliario/home", urlRedirectPdn: "https://tu360.grupobancolombia.com/inmobiliario/home" },
    { clientId: "SIM", urlRedirectDev: urlSimPvi, urlRedirectQa: urlSimPvi, urlRedirectPdn: urlSimPvi },
    { clientId: "PVI", urlRedirectDev: urlSimPvi, urlRedirectQa: urlSimPvi, urlRedirectPdn: urlSimPvi },
    { clientId: "NET", urlRedirectDev: urlNet, urlRedirectQa: urlNet, urlRedirectPdn: urlNet },
    { clientId: "MSC", urlRedirectDev: urlMsc, urlRedirectQa: urlMsc, urlRedirectPdn: urlMsc },
    { clientId: "GMN", urlRedirectDev: urlGmn, urlRedirectQa: urlGmn, urlRedirectPdn: urlGmn },
    { clientId: "ATD", urlRedirectDev: urlAtd, urlRedirectQa: urlAtd, urlRedirectPdn: urlAtd },
    { clientId: "PCB", urlRedirectDev: urlPcb, urlRedirectQa: urlPcb, urlRedirectPdn: urlPcb },
]

export const listClientId = [
    "MCE",
    "MKA",
    "MKE",
    "PCE",
    "SIM",
    "PVI",
    "NET",
    "MSC",
    "GMN",
    "ATD", 
    "PCB"
]
