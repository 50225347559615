import { createMachine, assign } from "xstate";

export const passwordFieldMachine = createMachine(
  {
    id: "passwordField",
    initial: "idle",

    context: {
      activeFieldName: "field1",
      currentValue: "",
      countValue: 0,
      field1: {
        value: "",
        mask: "",
        nextField: "field2",
        previousField: "field1",
      },
      field2: {
        value: "",
        mask: "",
        nextField: "field3",
        previousField: "field1",
      },
      field3: {
        value: "",
        mask: "",
        nextField: "field4",
        previousField: "field2",
      },
      field4: {
        value: "",
        mask: "",
        nextField: "field4",
        previousField: "field3",
      },
    },
    states: {
      idle: {
        on: {
          CHANGE_ACTIVE: {
            target: "idle",
            actions: ["changeActive"],
          },
          ADD_VALUE: {
            target: "idle",
            actions: ["addValue", "valueChanged", "moveField", "setError"],
          },
          REMOVE_VALUE: {
            target: "idle",
            actions: ["removeValue", "valueChanged", "setError"],
          },
          LAST_FIELD: {
            target: "idle",
            actions: ["lastField", "moveField"],
          },
        },
      },
    },
  },
  {
    actions: {
      changeActive: assign((_, payload) => {
        const { fieldName } = payload;
        return {
          activeFieldName: fieldName,
        };
      }),
      moveField: () => {},
      setError: () => {},
      addValue: assign((context, payload) => {
        const { fieldName, value, mask } = payload;
        const currentValue = context[fieldName];
        const countValue = context.countValue;
        return {
          [fieldName]: {
            ...currentValue,
            value,
            mask,
          },
          activeFieldName: currentValue.nextField,
          countValue: countValue + 1,
        };
      }),
      removeValue: assign((context, payload) => {
        const { fieldName } = payload;
        const currentValue = context[fieldName];
        const countValue = context.countValue;
        return {
          [fieldName]: {
            ...currentValue,
            value: "",
            mask: "",
          },
          activeFieldName: currentValue.previousField,
          countValue: countValue - 1,
        };
      }),
      valueChanged: assign(ctx => {
        const { field1, field2, field3, field4 } = ctx;
        return {
          currentValue: [field1, field2, field3, field4]
            .map(i => i.value)
            .join(""),
        };
      }),
      lastField: assign(ctx => {
        const { field1, field2, field3, field4 } = ctx;
        const focusField = [field1, field2, field3, field4]
          .filter(e => e.value.length !== 0)
          .pop();
        return {
          activeFieldName:
            focusField === undefined ? "field1" : focusField.nextField,
        };
      }),
    },
  }
);

export const stateMachine = createMachine(passwordFieldMachine);
