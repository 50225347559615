import styled from "styled-components";
import unavailabilityImge from "../../Images/unavailabilityIconScreen.svg";

export const Image = styled.div`
  background-image: url(${unavailabilityImge});
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  width: 224px;
  height: 224px;
`;