import { useState, useEffect } from 'react';

const usePageLoadEffect = () => {
  const [initialUrl, setInitialUrl] = useState('');

    useEffect(() => {
      const handleLoad = () => {
        const url = localStorage.getItem("startUrl");
        if (url && url !== window.location.href) {
            setInitialUrl(url);
        }
      };

      window.addEventListener("load", handleLoad);

      return () => {
        window.removeEventListener("load", handleLoad);
      };
    }, []);

    useEffect(() => {
      if (initialUrl) {
        window.location.replace(initialUrl);
      }
    }, [initialUrl]);
};

export default usePageLoadEffect;
