import styled from "styled-components";

export const ErrorFront = styled.div`
  width: 80%;
  color: #fa5e5b;
  font-family: "Open Sans";
  font-size: 12px;
  font-weight: bold;
  letter-spacing: -0.2px;
  line-height: 20px;
  margin: 0.5em auto 2em auto;
`;
