import styled from "styled-components";

const Container = styled.div`
  border-radius: 16px;
  padding: 2em;
  width: 556px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;

  @media screen and (min-width: 280px) and (max-width: 480px) {
    width: 100vw;
  }

  @media screen and (min-width: 481px) and (max-width: 961px) {
    width: 80vw;
  }

  @media screen and (width: 320px) and (height: 568px) {
    width: 80vw;
  }

  @media screen and (width: 568px) and (height: 320px) and (orientation: landscape) {
    width: 70vw;
  }

  @media screen and (width: 600px) and (height: 960px) and (orientation: portrait) {
    width: 80vw;
  }

  @media screen and (width: 1280px) and (height: 800px) and (orientation: landscape) {
    width: 35vw;
  }

  @media screen and (width: 883px) and (height: 412px) and (orientation: landscape) {
    width: 50vw;
  }

  @media screen and (width: 280px) and (height: 653px) and (orientation: portrait) {
    width: 120vw;
  }

  @media screen and (width: 280px) and (height: 663px) and (orientation: portrait) {
    width: 120vw;
  }

  @media screen and (min-width: 666px) and (max-width: 800px) and (orientation: landscape) {
    margin-top: 20px;
    width: 80vw;
  }

  @media screen and (min-width: 802px) and (max-width: 850px) and (orientation: landscape) {
    margin-top: 20px;
    width: 70vw;
  }

  @media screen and (min-width: 852px) and (max-width: 984px) and (orientation: landscape) {
    margin-top: 20px;
  }
`;
export default Container;
