import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import iconlock from "../Images/lock.svg";

const Container = styled.div`
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.div`
  display: flex;
  justify-content: center;
  width: ${props => props.widthTitle};
  text-align: center;
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 24px;
  letter-spacing: -0.375px;
  color: #292929;
  margin-bottom: 15px;

  @media screen and (min-width: 280px) and (max-width: 480px) {
    width: 90%;
    font-size: 25px;
  }
`;

const SubTittle = styled.div`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  letter-spacing: -0.3px;
  color: #2C2A29;
  width: 432px;
  flex: none;
  order: 2;
  flex-grow: 0;

  @media screen and (min-width: 280px) and (max-width: 480px) {
    width: 90%;
    font-size: 15px;
  }

  @media screen and (min-width: 961px) and (max-width: 1024px) {
    width: 432px;
  }

  @media screen and (min-width: 320px) and (max-width: 850px) and (orientation: landscape) and (min-aspect-ratio: 13/9) {
    width: 90%;
  }
`;

const StyledIcon = styled.div`
  background-image: url(${iconlock});
  background-repeat: no-repeat;
  width: 21px !important;
  height: 24px;
  text-align: center;
  order: 1;
  margin: 1em auto;

  @media screen and (min-width: 280px) and (max-width: 480px) {
    width: 60px;
  }
`;

const OtherDescription = styled.div`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  letter-spacing: -0.3px;
  color: #2C2A29;
  width: 432px;
  flex: none;
  order: 3;
  flex-grow: 0;

  @media screen and (min-width: 280px) and (max-width: 480px) {
    width: 90%;
    font-size: 15px;
  }

  @media screen and (min-width: 961px) and (max-width: 1024px) {
    width: 432px;
  }

  @media screen and (min-width: 320px) and (max-width: 850px) and (orientation: landscape) and (min-aspect-ratio: 13/9) {
    width: 90%;
  }
`;


function ContainerHeader({
  title,
  subTitle,
  otherDescription,
  icon,
  topM,
  widthTitle,
  widthSubTitle,
  topSubTittle,
  heightL,
}) {
  return (
    <>
      <Container>
        {icon && <StyledIcon src={iconlock} />}
        <Title widthTitle={widthTitle}>{title}</Title>
        <SubTittle
          widthSubTitle={widthSubTitle}
          topSubTittle={topSubTittle}
          topM={topM}
          heightL={heightL}
        >
          {subTitle}
        </SubTittle>
        <OtherDescription
          widthSubTitle={widthSubTitle}
          topSubTittle={topSubTittle}
          topM={topM}
          heightL={heightL}
        >
          {otherDescription}
        </OtherDescription>
        
      </Container>
    </>
  );
}

ContainerHeader.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  widthTitle: PropTypes.string,
  widthSubTitle: PropTypes.string,
  topSubTittle: PropTypes.string,
  icon: PropTypes.bool.isRequired,
};

export default ContainerHeader;