import styled from "styled-components";

const Button = styled.button`
  width: 458px;
  height: 82px;
  border: none;
  border-radius: 8px;
  display: flex;
  align-items: center;
  font-size: 18px;
  outline: none;
  text-align: right;
  cursor: pointer;
  padding: 0px;
  background-color: #ffffff;

  /*tablet*/
  @media screen and (min-width: 768px) and (max-width: 991px) {
    width: 472px;
  }

  /*mobile*/
  @media screen and (min-width: 576px) and (max-width: 767px) {
    width: 358px;
  }

  @media screen and (min-width: 321px) and (max-width: 575px) {
    width: 343px;
  }

  @media screen and (min-width: 0px) and (max-width: 320px) {
    width: 300px;
  }
`;
export default Button;
