import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Provider } from "../Context";
import { ProviderMachine } from "../Context/contextMachine";
import { UserForm } from "./FlowUserAndPinV1/UserForm";
import { PasswordForm } from "./FlowUserAndPinV1/PasswordForm";
import { DynamicKeyForm } from "./DynamicKeyForm";
import { AppAtHandForm } from "./FlowPersonalDataAndOtp/AppAtHandForm";
import { SecurityCodeForm } from "./FlowPersonalDataAndOtp/SecurityCodeForm";
import { DynamicKeyFirstFactor } from "./FlowDinamicKeyFirstFactor/DynamicKeyFirstFactor";
import { UnavailabilityScreen } from "./UnavailabilityScreen"
import { TokenForm } from "./FlowAlphanumericKeyAndToken/TokenForm"
import {
  userFormPath, dynamicKeyPath, appHandFormPath, authDynamicKeyPath,
  windowPathname, userFormPathV2, appHandFormPathV2, dynamicKeySignaturePathV2, dynamicKeyScreenPath,
  choiceAppHandV2, userFormAlphanumericPath, alphanumerickeyFormPath, tokenSecondFactorFormPath, unavailabilityV2,
  passwordFormPathv2, passwordFormPath
} from "../Constant/Constants"
import { ChoiceAppPay } from "./AuthenticationSelectionScreen/ChoiceAppPay";
import { AlphanumericScreen } from "./FlowAlphanumericKeyAndToken/AlphanumericScreen";

import fuaMachine from "../StateMachines/validateUrlStateMachine";
import appAtHandMachine from "../StateMachines/appAtHandMachine";
import dynamicKeyMachine from "../StateMachines/dynamicKeyMachine";
import authDynamicKeyMachine from "../StateMachines/authDynamicKeyMachine";
import alphanumericKeyMachine from "../StateMachines/alphanumericKeyAndTokenSfMachine";
import { Unavailability } from "./Unavailability"
import { UserFormAlphanumericKey } from "./FlowAlphanumericKeyAndToken/UserFormAlphanumericKey";
import { UserFormV2 } from "./FlowUserAndPinV2/UserFormV2";
import { UserFormDynamicKey } from "./FlowUserAndDynamicKey/UserFormDynamicKey";
import { AuthDynamicKeyForm } from "./FlowUserAndDynamicKey/DynamicKeyForm"
import { PasswordFormV2 } from "./FlowUserAndPinV2/PasswordFormV2";

const stateMachineMap = {
  [userFormPath]: fuaMachine,
  [appHandFormPath]: appAtHandMachine,
  [dynamicKeyPath]: dynamicKeyMachine,
  [authDynamicKeyPath]: authDynamicKeyMachine,
  [userFormPathV2]: fuaMachine,
  [userFormAlphanumericPath]: alphanumericKeyMachine,
  [dynamicKeySignaturePathV2]: dynamicKeyMachine,
  [appHandFormPathV2]: appAtHandMachine,
};

export function selectStateMachine(pathname) {
  return stateMachineMap[pathname] || fuaMachine;
}

function Main() {
  const typeMachine = selectStateMachine(windowPathname);
  return (
    <Router>
      <ProviderMachine typeMachine={typeMachine}>
        <Switch>
          <Provider>
            <>
              <Route path={userFormPath} exact component={UserForm} />
              <Route path={passwordFormPath} exact component={PasswordForm} />
              <Route path="/dynamicKeyForm" exact component={DynamicKeyForm} />
              <Route path={appHandFormPath} exact component={AppAtHandForm} />
              <Route path="/securityCodeForm" exact component={SecurityCodeForm} />
              <Route path="/login/oauth/appAuth" exact component={ChoiceAppPay} />
              <Route path={dynamicKeyPath} exact component={DynamicKeyFirstFactor} />
              <Route path={authDynamicKeyPath} exact component={UserFormDynamicKey} />
              <Route path="/unavailability" exact component={UnavailabilityScreen} />
              <Route path={userFormPathV2} exact component={UserFormV2} />
              <Route path={choiceAppHandV2} exact component={ChoiceAppPay} />
              <Route path={appHandFormPathV2} exact component={AppAtHandForm} />
              <Route path="/securityCodeForm/V2" exact component={SecurityCodeForm} />
              <Route path={dynamicKeySignaturePathV2} exact component={DynamicKeyFirstFactor} />
              <Route path={dynamicKeyScreenPath} exact component={AuthDynamicKeyForm} />
              <Route path={userFormAlphanumericPath} exact component={UserFormAlphanumericKey} />
              <Route path={alphanumerickeyFormPath} exact component={AlphanumericScreen} />
              <Route path={tokenSecondFactorFormPath} exact component={TokenForm} />
              <Route path={unavailabilityV2} exact component={Unavailability} />
              <Route path={passwordFormPathv2} exact component={PasswordFormV2} />
            </>
          </Provider>
        </Switch>
      </ProviderMachine>
    </Router>
  );
}
export default Main;
