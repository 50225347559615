import styled from "styled-components";

export const ErrorFront = styled.div`
  width: 100%;
  color: #fa5e5b;
  font-family: "Open Sans";
  font-size: 12px;
  font-weight: bold;
  letter-spacing: -0.2px;
  line-height: 20px;
  margin: 4.3em auto auto auto;
  line-height: 16px;
  position: absolute;
  text-align: center;

  @media screen and (min-width: 568px)  and (max-width: 844px) and (orientation: landscape) {
    margin: 5.3em auto auto auto;
  }
`;