import getUrlParam, { getDevice, getValueCookie } from "../Utilities/httpUtils";
import axios from "axios";
import config from "../config";
import { createMachine, assign } from "xstate";
import {
    USER_AGENT,
    informationScreen,
    informationNavigator,
    contentType,
    scoreRecaptchaCodeErrror,
    cookieNameCsid
} from "../Constant/Constants";
import forge from "node-forge"
import {
    incorrectData,
    systemNotAvailable,
    maximumNumberAttempts,
    keyExceededLifetime,
    validMigratedStates,
    errorUserNotRegisteredPilot,
    migratedStatedBlocked
} from "../Constant/ConstantsAlphanumericKey"
import {
    errorNewlyCreatedDynamicKey,
    errorNullOrEmptyFields,
    errorGeneric,
    errorDowntimeExceeded,
    errorIncorrectDynamicKey,
    errorNumberAattemptsExceeded,
    errorDynamicKeyBlocked,
    errorCustomerNotEnrolled,
} from "../Constant/ConstantsSoftToken"
import { validateRedirect } from "../Utilities/GeneralFunctions"

const URLGETIP = config.url.URLGETIP;
const URLAUTHORIZE = config.url.URLAUTHORIZE;
const URLGETALPHANUMERICKEY = config.urlAlphanumericKeyAndToken.URLGETPUBLICALPHANUMERICKEY
const URLVALIDATEALPHANUMERICKEY = config.urlAlphanumericKeyAndToken.URLVALIDATEALPHANUMERICKEY
const URLVALIDATETOKENSF = config.urlAlphanumericKeyAndToken.URLVALIDATETOKENSF
const URLVALIDTEISMIGRATEDALPHANUMERICKEY = config.urlAlphanumericKeyAndToken.URLVALIDTEISMIGRATEDALPHANUMERICKEY

const actions = {
    setUsername: assign({ username: (_context, event) => event.username }),
    setRecaptcha: assign({recapvisible: (_context, event) => event.data.attempts > 0}),
    setRecaptchaDefault: assign({recapvisible: _context => true })
};

const conditions = {
    successAttemps: (_context, event) => event.data.attempts !== undefined && event.data.attempts !== null
};
const initialStateValidateAlphanumericKey = "#alphanumerickey.validateAlphanumericKeyAndTokenSf.continueFlow"

const authDcFetchValidateParameters = async () => {
    const ip = await fetch(URLGETIP).then(response => response.json());
    return {
        currentIp: ip,
        idClient: getUrlParam("client_id"),
        responseType: getUrlParam("response_type"),
        redirectUri: getUrlParam("redirect_uri"),
        trace: getUrlParam("trace"),
        prompt: getUrlParam("prompt"),
        linkCode: getUrlParam("linkCode"),
        businessPartner: getUrlParam("businessPartner"),
        startUrl: document.URL
    };
};

const getDataValidateUrl = async context => {
    return {
        data: {
            data: [
                {
                    type: "validation",
                    id: context.idClient,
                    attributes: {
                        responseType: context.responseType,
                        redirectUri: context.redirectUri,
                        ipConsumidor: context.currentIp.ip,
                        trace: context.trace,
                        prompt: context.prompt,
                        linkCode: context.linkCode,
                        businessPartner: context.businessPartner,
                    },
                },
            ],
        },
    };
}

const validateUrl = async context => {
    const PARAMS = await getDataValidateUrl(context);
    if (context.idClient !== "" && context.idClient !== null) {
        const headersAuthorize = { contentType };
        return axios
            .post(URLAUTHORIZE, PARAMS.data, { headersAuthorize })
            .then(response => response.data);
    }
    throw new Error("clientId no especificado");
};

const getPublicKey = async () => axios.get(URLGETALPHANUMERICKEY).then(response => response.data)


const validatePrompt = async context => {
    if (context.prompt !== null && typeof context.prompt === "string") {
        context.prompt = context.prompt.replace(context.idClient + "-", "").split("|")
    }
}

const getInformationValidate = async (context, username, password, captchaToken) => {
    return {
        data: [
            {
                username: username,
                alphanumericKeyCode: password,
                userAgent: window.navigator.userAgent.slice(0, USER_AGENT),
                consumerId: context.idClient,
                device: await getDevice(),
                localAddress: context.currentIp.ip,
                factors: ["AK", "SF"],
                captchaToken: captchaToken,
                redirectUri: context.redirectUri,
                traceId: context.trace,
                segment: "APP",
                csid: await getValueCookie(cookieNameCsid),
                info: {
                    screen: informationScreen,
                    navigator: informationNavigator,
                    time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                }
            }
        ],
    };
}

const encryptAlphanumericKey = async pass => {
    const publicKey = await getPublicKey()
    const dateNow = new Date()
    const password = `${pass}|${dateNow.toISOString()}`
    const publicKeyFoge = forge.pki.publicKeyFromPem(publicKey)
    return forge.util.encode64(
        publicKeyFoge.encrypt(forge.util.encodeUtf8(password), "RSA-OAEP", {
            md: forge.md.sha256.create(),
        })
    )
}

const isMigrated = async (context, event) => {
    const { username } = event;
    context.eventAuthAlphanumeric = event
    const PARAMS = {
        data: [
            {
                username: username,
                transactionId: context.trace
            }
        ]
    }
    return axios
        .post(URLVALIDTEISMIGRATEDALPHANUMERICKEY, PARAMS.data[0])
        .then(response => response.data);
}

const validateAlphanumericKey = async context => {
    const { username, password, captchaToken } = context.eventAuthAlphanumeric;
    const passwordEncrypt = await encryptAlphanumericKey(password)
    await validatePrompt(context);
    const PARAMS = await getInformationValidate(context, username, passwordEncrypt, captchaToken);
    return axios
        .post(URLVALIDATEALPHANUMERICKEY, PARAMS.data[0])
        .then(response => response.data);
}

const validateTokenSf = async (context, event) => {
    const { otp } = event
    const request = {
        data: [
            {
                code: context.ca,
                channel: context.idClient,
                otp: otp,
                traceId: context.trace,

            }
        ],
    }
    return axios
        .post(URLVALIDATETOKENSF, request.data[0])
        .then(response => response.data);
}

const getBodyValidateRecaptcha = async context => {
    return {
      data: [{
        trace: context.trace,
        username: context.username,
        documentType: "",
        documentNumber: ""
      }]
    }
  }

const initialContext = {
    status: "",
    error: "",
    currentIp: "",
    idClient: "",
    responseType: "",
    redirectUri: "",
    auth: "",
    attemps: 0,
    trace: "",
    errorCode: "",
    prompt: "",
    linkCode: "",
    businessPartner: "",
    startUrl: "",
    ca: "",
    urlFinalRedirect: "",
    eventAuthAlphanumeric: "",
    recapvisible: true
};

const alphanumericKeyMachine = createMachine(
    {
        id: "alphanumerickey",
        initial: "validateRedirect",
        context: initialContext,
        predictableActionArguments: true,
        preserveActionOrder: true,
        states: {
            validateRedirect: {
                invoke: {
                    id: "validateRedirectId",
                    src: validateRedirect,
                    onDone: [
                        {
                            cond: (_context, event) => event.data.redirectUrl,
                            actions: assign({ redirectUrl: (_context, event) => event.data.redirectUrl }),
                            target: "redirect",
                        },
                        {
                            target: "authValidateParameters",
                        },
                    ],
                    onError: {
                        target: "errorForm",
                    },
                },
            },
            authValidateParameters: {
                invoke: {
                    id: "authValidateParametersId",
                    src: authDcFetchValidateParameters,
                    onDone: {
                        target: "authValidateUrl",
                        actions: assign({
                            trace: (_context, event) => event.data.trace,
                            prompt: (_context, event) => event.data.prompt,
                            linkCode: (_context, event) => event.data.linkCode,
                            businessPartner: (_context, event) => event.data.businessPartner,
                            startUrl: (_context, event) => event.data.startUrl,
                            currentIp: (_context, event) => event.data.currentIp,
                            idClient: (_context, event) => event.data.idClient,
                            responseType: (_context, event) => event.data.responseType,
                            redirectUri: (_context, event) => event.data.redirectUri
                        }),
                    },
                    onError: {
                        target: "errorForm",
                    },
                },
            },
            authValidateUrl: {
                invoke: {
                    id: "AuthValidateUrl",
                    src: validateUrl,
                    onError: {
                        target: "errorForm",
                        actions: assign({
                            error: (_context, event) => event.data,
                        }),
                    },
                    onDone: [
                        {
                            target: "errorForm",
                            cond: (_context, event) => event.data.errors,
                            actions: assign({ errorCode: (_context, event) => event.data.errors[0].code }),
                        },
                        {
                            target: "validateAlphanumericKeyAndTokenSf",
                            actions: assign({
                                status: (_context, event) => event.data
                            }),
                        },
                    ]
                },
            },
            validateAlphanumericKeyAndTokenSf: {
                initial: "checkRecaptcha",
                states: {
                    checkRecaptcha: {
                        on: {
                          RETRY: [
                            { target: "continueFlow", actions: actions.setUsername}
                          ],
                        }
                    },
                    continueFlow:{
                      after: {
                        1: {  target: "validate" },
                      }
                    },
                    validate: {
                        on: {
                            VALIDATEALPHANUMERICKEY: [
                                {
                                    target: "isMigrated",
                                }
                            ],
                            RETRY: [
                                { target: "continueFlow", actions: actions.setUsername}
                            ]
                        },
                    },
                    isMigrated: {
                        invoke: {
                            id: "isMigrated",
                            src: isMigrated,
                            onError: [
                                {
                                    target: "errorService",
                                    actions: assign({ errorCode: (_context, event) => event.data }),
                                }
                            ],
                            onDone: [
                                {
                                    cond: (_context, event) => event.data.errors && event.data.errors[0].code === errorUserNotRegisteredPilot,
                                    target: "errorUserNotRegisteredPilot",
                                    actions: assign({ errorCode: (_context, event) => event.data.errors[0].code }),
                                },
                                {
                                    cond: (_context, event) => event.data.errors && systemNotAvailable.includes(event.data.errors[0].code),
                                    target: "errorServiceMigrated",
                                    actions: assign({ errorCode: (_context, event) => event.data.errors[0].code }),
                                },
                                {
                                    cond: (_context, event) => !event.data.errors && migratedStatedBlocked === event.data.data.user_status,
                                    target: "errorUserMigratedBlocked",
                                },
                                {
                                    cond: (_context, event) => !event.data.errors && !validMigratedStates.includes(event.data.data.user_status),
                                    target: "errorUserNotMigrated",
                                },
                                {
                                    cond: (_context, event) => event.data.errors,
                                    target: "errorService",
                                    actions: assign({ errorCode: (_context, event) => event.data.errors[0].code }),
                                },
                                {
                                    cond: (_context, event) => validMigratedStates.includes(event.data.data.user_status),
                                    target: "validateAuthAlphanumericKey",
                                }
                            ]
                        }
                    },
                    errorUserNotRegisteredPilot: {
                        on: {
                            RETRY: {
                                target: "validate",
                            },
                        },
                    },
                    errorServiceMigrated: {
                        on: {
                            RETRY: {
                                target: "validate",
                            },
                        },
                    },
                    errorUserNotMigrated: {
                        on: {
                            RETRY: {
                                target: "validate",
                            },
                        },
                    },
                    errorUserMigratedBlocked: {
                        on: {
                            RETRY: {
                                target: "validate",
                            },
                        },
                    },
                    validateAuthAlphanumericKey: {
                        invoke: {
                            id: "validateAuthAlphanumericKey",
                            src: validateAlphanumericKey,
                            onError: [
                                {
                                    target: "errorService",
                                    actions: assign({ errorCode: (_context, event) => event.data }),
                                }
                            ],
                            onDone: [
                                {
                                    cond: (_context, event) => event.data.errors && systemNotAvailable.includes(event.data.errors[0].code),
                                    target: "errorService",
                                    actions: assign({ errorCode: (_context, event) => event.data.errors[0].code })
                                },
                                {
                                    cond: (_context, event) => event.data.errors && maximumNumberAttempts.includes(event.data.errors[0].code),
                                    target: "errorMaxAttemps",
                                    actions: assign({ errorCode: (_context, event) => event.data.errors[0].code })
                                },
                                {
                                    cond: (_context, event) => event.data.errors && incorrectData.includes(event.data.errors[0].code),
                                    target: "errorIncorrectData",
                                    actions: assign({ errorCode: (_context, event) => event.data.errors[0].code })
                                },
                                {
                                    cond: (_context, event) => event.data.errors && event.data.errors[0].code === keyExceededLifetime,
                                    target: "errorKeyExceededLifetime",
                                    actions: assign({ errorCode: (_context, event) => event.data.errors[0].code })
                                },
                                {
                                    cond: (_context, event) => event.data.errors && event.data.errors[0].code === scoreRecaptchaCodeErrror,
                                    target: "errorScore",
                                    actions: assign({ errorCode: (_context, event) => event.data.errors[0].code })
                                },
                                {
                                    cond: (_context, event) => event.data.errors,
                                    target: "errorService",
                                    actions: assign({ errorCode: (_context, event) => event.data.errors[0].code }),
                                },
                                {
                                    target: "validateAuthTokenSf",
                                    actions: assign({
                                        ca: (_context, event) => event.data.url.split("code=")[1],
                                        urlFinalRedirect: (_context, event) => event.data.url
                                    })
                                }
                            ]
                        },
                    },
                    errorIncorrectData: {
                        on: {
                            RETRY: {
                                target: "validate",
                            },
                        },
                    },
                    errorMaxAttemps: {
                        on: {
                            RETRY: {
                                target: "validate",
                            },
                        },
                    },
                    errorKeyExceededLifetime: {
                        on: {
                            RETRY: {
                                target: "validate",
                            },
                        },
                    },
                    errorService: {
                        on: {
                            RETRY: {
                                target: "validate",
                            },
                        },
                    },
                    errorScore: {
                        on: {
                            RETRY: { target: "continueFlow", actions: actions.setUsername}
                        },
                    },
                    validateAuthTokenSf: {
                        initial: "validateTokenInitial",
                        states: {
                            validateTokenInitial: {
                                on: {
                                    VALIDATEAUTHTOKENSF: [
                                        {
                                            target: "validateAuthDataTokenSf",
                                        }
                                    ],
                                },
                            },
                            validateAuthDataTokenSf: {
                                invoke: {
                                    id: "validateAuthDataTokenSf",
                                    src: validateTokenSf,
                                    onError: [
                                        {
                                            target: "errorService",
                                            actions: assign({ errorCode: (_context, event) => event.data }),
                                        }
                                    ],
                                    onDone: [
                                        {
                                            cond: (_context, event) => event.data.errors && errorGeneric.includes(event.data.errors[0].code),
                                            target: "errorGeneric",
                                            actions: assign({ errorCode: (_context, event) => event.data.errors[0].code })
                                        },
                                        {
                                            cond: (_context, event) => event.data.errors && errorNewlyCreatedDynamicKey.includes(event.data.errors[0].code),
                                            target: "errorNewlyCreatedDynamicKey",
                                            actions: assign({ errorCode: (_context, event) => event.data.errors[0].code })
                                        },
                                        {
                                            cond: (_context, event) => event.data.errors && errorNullOrEmptyFields.includes(event.data.errors[0].code),
                                            target: "errorNullOrEmptyFields",
                                            actions: assign({ errorCode: (_context, event) => event.data.errors[0].code })
                                        },
                                        {
                                            cond: (_context, event) => event.data.errors && event.data.errors[0].code === errorDowntimeExceeded,
                                            target: "errorDowntimeExceeded",
                                            actions: assign({ errorCode: (_context, event) => event.data.errors[0].code })
                                        },
                                        {
                                            cond: (_context, event) => event.data.errors && errorIncorrectDynamicKey.includes(event.data.errors[0].code),
                                            target: "errorIncorrectDynamicKey",
                                            actions: assign({ errorCode: (_context, event) => event.data.errors[0].code })
                                        },
                                        {
                                            cond: (_context, event) => event.data.errors && errorNumberAattemptsExceeded.includes(event.data.errors[0].code),
                                            target: "errorNumberAattemptsExceeded",
                                            actions: assign({ errorCode: (_context, event) => event.data.errors[0].code })
                                        },
                                        {
                                            cond: (_context, event) => event.data.errors && event.data.errors[0].code === errorDynamicKeyBlocked,
                                            target: "errorDynamicKeyBlocked",
                                            actions: assign({ errorCode: (_context, event) => event.data.errors[0].code })
                                        },
                                        {
                                            cond: (_context, event) => event.data.errors && event.data.errors[0].code === errorCustomerNotEnrolled,
                                            target: "errorCustomerNotEnrolled",
                                            actions: assign({ errorCode: (_context, event) => event.data.errors[0].code })
                                        },
                                        {
                                            cond: (_context, event) => event.data.errors,
                                            target: "errorService",
                                            actions: assign({ errorCode: (_context, event) => event.data.errors[0].code }),
                                        },
                                        {
                                            target: "success",
                                            actions: ["redirectToUrl"],
                                        }
                                    ]
                                }
                            },
                            success: {
                                after: {
                                    2000: {
                                        type: "final",
                                    },
                                },
                            },
                            errorService: {
                                type: "final",
                            },
                            errorGeneric: {
                                type: "final",
                            },
                            errorNewlyCreatedDynamicKey: {
                                on: {
                                    RETRY: {
                                        target: initialStateValidateAlphanumericKey,
                                    },
                                },
                            },
                            errorNullOrEmptyFields: {
                                on: {
                                    RETRY: {
                                        target: initialStateValidateAlphanumericKey, actions: actions.setUsername
                                    },
                                },
                            },
                            errorDowntimeExceeded: {
                                on: {
                                    RETRY: {
                                        target: initialStateValidateAlphanumericKey,
                                    },
                                },
                            },
                            errorIncorrectDynamicKey: {
                                on: {
                                    RETRY: {
                                        target: "validateTokenInitial",
                                    },
                                },
                            },
                            errorNumberAattemptsExceeded: {
                                on: {
                                    RETRY: {
                                        target: initialStateValidateAlphanumericKey,
                                    },
                                },
                            },
                            errorDynamicKeyBlocked: {
                                on: {
                                    RETRY: {
                                        target: initialStateValidateAlphanumericKey,
                                    },
                                },
                            },
                            errorCustomerNotEnrolled: {
                                on: {
                                    RETRY: {
                                        target: initialStateValidateAlphanumericKey,
                                    },
                                },
                            },
                        }
                    },
                }
            },
            redirect: {
                type: "final",
                entry: ["redirectToTarget"],
            },

            errorForm: {
                type: "final",
            },
        },
    },

    {
        actions: {
            redirectToUrl: assign(_context => {
                const urlRedirect = _context.trace !== null && _context.trace !== "" ? `${_context.urlFinalRedirect}&trace=${_context.trace}` : _context.urlFinalRedirect
                window.location.replace(urlRedirect)
            }),
            redirectToTarget: _context => window.location.replace(_context.urlRedirect)
        },
    }
);

export default alphanumericKeyMachine;
