import styled from "styled-components";

export const Button = styled.button`
  background: #fdda24;
  color: #292929;
  border: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 240px;
  height: 38px;
  position: relative;
  border-radius: 100px;
  font-family: Nunito;
  font-size: 16px;
  outline: none;
  top: 5px;

  @media screen and (min-width: 280px) and (max-width: 480px) {
    width: 295px;
    margin-bottom: 10px;
    top: 0;
  }
`;