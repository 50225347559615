import errorImageScoreRecaptcha from "../../Images/errorImageScoreRecaptcha.svg";
import errorImageBlock from "../../Images/alertIcon.svg";
import ModalErrorTemplate from "../../Components/ModalErrorTemplate/ModalErrorTemplate";
import { useHistory } from "react-router-dom";
import { getTrace } from '../../Utilities/httpUtils'
import { unavailabilityV2, EFA013, EFA014, EFA015, EFA016, EFA017 } from "../../Constant/Constants"
import RedirectionMessage from "../../Components/RedirectionMessage";
import React, { useEffect } from "react";
import { contextSwitchHandlerBiocatch } from "../../Utilities/GeneralFunctions"

export function HandlerTokenForm({ machine }) {
    const history = useHistory();
    const isMatchUnavailability = ["validateAlphanumericKeyAndTokenSf.validateAuthTokenSf.errorGeneric", "validateAlphanumericKeyAndTokenSf.validateAuthTokenSf.errorService"]
    const isMatchRedirectMessage = ["validateAlphanumericKeyAndTokenSf.validateAuthTokenSf.success", "validateAlphanumericKeyAndTokenSf.validateAuthTokenSf.validateAuthDataTokenSf", "validateAlphanumericKeyAndTokenSf.validateAuthTokenSf.redirectToUrl"]

    useEffect(() => {
        contextSwitchHandlerBiocatch("TOKEN_FORM")
    }, []);

    const goToHome = (event, efa) => {
        event.preventDefault();
        window.location.replace(machine.context.redirectUri + efa + getTrace(machine.context))
    }

    const goToInitialScreen = () => {
        history.push("/login" + machine.context.startUrl.split("login")[1])
    }

    return (
        <div>
            {(isMatchUnavailability.some(machine.matches)) && (() => { history.push(unavailabilityV2); })()}
            {(isMatchRedirectMessage.some(machine.matches)) && (<RedirectionMessage />)}
            {machine.matches("validateAlphanumericKeyAndTokenSf.validateAuthTokenSf.errorNewlyCreatedDynamicKey") && (<ModalErrorTemplate
                iconImageSvg={errorImageScoreRecaptcha}
                title="Algo salió mal"
                description="Lo sentimos, si recientemente realizaste la inscripción al servicio de Clave Dinámica, recuerda que en transcurso de dos horas te notificaremos al celular o correo electrónico el estado de tu Clave Dinámica. Si recientemente bloqueaste la Clave Dinámica, para activarla dirígete a un Cajero Automático y cambia la Clave Principal."
                functionButtonDescription={event => goToHome(event, EFA013)}
                buttonText="Entendido"
                height={"390px"}
                heightM={"500px"}
                hiddenAditionalDescription={true}
            />)}
            {machine.matches("validateAlphanumericKeyAndTokenSf.validateAuthTokenSf.errorNullOrEmptyFields") && (<ModalErrorTemplate
                iconImageSvg={errorImageScoreRecaptcha}
                title="Algo salió mal"
                description="Parece que la información que enviaste no llegó completa, intentalo nuevamente."
                functionButtonDescription={goToInitialScreen}
                buttonText="Entendido"
                height={"330px"}
                heightM={"330px"}
                hiddenAditionalDescription={true}
            />)}
            {machine.matches("validateAlphanumericKeyAndTokenSf.validateAuthTokenSf.errorDowntimeExceeded") && (<ModalErrorTemplate
                iconImageSvg={errorImageScoreRecaptcha}
                isShowCloseButton={false}
                title="Algo salió mal"
                description="Lo sentimos, Inténtalo nuevamente. Se ha superado el tiempo de inactividad permitido para navegar en la aplicación"
                functionButtonDescription={event => goToHome(event, EFA014)}
                buttonText="Entendido"
                height={"335px"}
                heightM={"340px"}
                hiddenAditionalDescription={true}
            />)}
            {machine.matches("validateAlphanumericKeyAndTokenSf.validateAuthTokenSf.errorNumberAattemptsExceeded") && (<ModalErrorTemplate
                iconImageSvg={errorImageScoreRecaptcha}
                isShowCloseButton={false}
                title="Superaste el número de intentos"
                description="Intenta de nuevo en 2 horas."
                functionButtonDescription={event => goToHome(event, EFA015)}
                buttonText="Entendido"
                height={"320px"}
                heightM={"340px"}
                hiddenAditionalDescription={true}
            />)}
            {machine.matches("validateAlphanumericKeyAndTokenSf.validateAuthTokenSf.errorDynamicKeyBlocked") && (<ModalErrorTemplate
                iconImageSvg={errorImageBlock}
                isShowCloseButton={false}
                title="Importante"
                description={"Lo sentimos, tu clave dinámica se encuentra bloqueada. Activala cambiando la Clave Principal en un Cajero Automático o Corresponsal Bancario. "
                    + "Si tienes Tarjeta Crédito Bancolombia, ingresa a la Sucursal Virtual Personas y en el costado inferior derecho "
                    + "entra a la opción de desbloqueo por Wompi, sigue las instrucciones y tu clave se activará en máximo 2 horas."}
                functionButtonDescription={event => goToHome(event, EFA016)}
                buttonText="Entendido"
                height={"410px"}
                heightM={"500px"}
                hiddenAditionalDescription={true}
            />)}
            {machine.matches("validateAlphanumericKeyAndTokenSf.validateAuthTokenSf.errorCustomerNotEnrolled") && (<ModalErrorTemplate
                iconImageSvg={errorImageBlock}
                isShowCloseButton={false}
                title="Importante"
                description="Para continuar con la transacción consulta y administra tu clave dinámica en la APP Personas"
                functionButtonDescription={event => goToHome(event, EFA017)}
                buttonText="Entendido"
                height={"340px"}
                heightM={"340px"}
                hiddenAditionalDescription={true}
            />)}
        </div>
    )
}