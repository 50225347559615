import styled from "styled-components";

export const Text = styled.div`
  font-family: "Open Sans";
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  color: rgba(44, 42, 41, 1);

  @media screen and (min-width: 0px) and (max-width: 575px) {
    font-size: 15.82px;
    line-height: 23.73px;
  }
`;
