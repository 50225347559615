import styled from "styled-components";

const colors = {
  primary: "#FDDA24",
  disable: "#F2F2F4",
  normal: "#FFFFFF",
  textColor: "#97999B",
  textColor2: "#292929",
};

const optionPrimary = "primary"
const optionNormal = "normal"
const pixel = "1px"
const border = "solid"
const colorValid = "#2C2A29"
const styleBorderNormal = `${pixel} ${border} ${colorValid};`
const pointer = "pointer"
const drop = "no-drop"
const noPixel = "0px"
const background = "background:"
const color = "color:"
const comma = ";"

const Button = styled.button`
  ${props => {
    if (props.typeButton === optionNormal) {
      return `
      ${background} ${colors.normal} ${comma}
      ${color} ${colors.textColor2} ${comma}
    `;
    }
    return `
    ${color} ${props.typeButton === optionPrimary ? colors.textColor2 : colors.textColor} ${comma}
    ${background} ${props.typeButton === optionPrimary ? colors.primary : colors.disable} ${comma}
    `;
  }}
  margin-bottom: 10px;
  border: ${props => props.typeButton === optionNormal ? styleBorderNormal : noPixel};
  cursor: ${props => props.typeButton === optionPrimary || props.typeButton === optionNormal ? pointer : drop};
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${props => props.width || "90%"};
  height: ${props => props.height || "50px"};
  border-radius: 100px;
  font-family: Nunito;
  font-size: 18px;
  outline: none;
  padding:  ${props => props.padding || "none"};

  @media screen and (min-width: 280px) and (max-width: 480px) {
    width: ${props => props.widthS || "90%"};
  }

  @media screen and (min-width: 481px) and (max-width: 961px) {
    width: ${props => props.widthM || "90%"};
  }

  @media screen and (min-width: 320px) and (max-width: 850px) and (orientation: landscape) and (min-aspect-ratio: 13/9) {
    width: 80%;
    margin-top: 25px;
  }

  @media screen and (min-width: 962px) and (max-width: 974px) and (orientation: landscape) {
    width: 85%;
  }
`;
export default Button;
