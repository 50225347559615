import axios from "axios";
import getUrlParam from "../Utilities/httpUtils";
import config from "../config";
import { contentType } from "../Constant/Constants";

const URLBEHAVIORALANALYSIS = config.url.URLBEHAVIORALANALYSIS;

export const behavioralAnalysis = async (context, url) => {
    const trace = context.trace === "" ? getUrlParam("trace") : context.trace
    const request = {
        trace_id: trace !== null ? trace : "",
        code: url.split("=")[1],
    }
    await axios.post(URLBEHAVIORALANALYSIS, request, { contentType })
}
