import styled from "styled-components";

export const FaChevronRight = styled.div`
  width: 40px;
  height: 82px;
  background-image: url(${props => props.image});
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 0px 8px 8px 0px;
  background-color: ${props => props.backgroundColor};
`;
