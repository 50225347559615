import ButtonTemplate from "../../ButtonTemplate/ButtonTemplate";
import {
    ButtonEnableStyle,
    ButtonDisableStyle,
    ButtonBackStyle,
} from "../../ButtonTemplate/ButtonTemplateStyle";
import { ButtonGroup } from "../../../Styles/AlphanumericScreen/ButtonGroup"
import { back } from "../../../Utilities/GeneralFunctions"
import { useMachineContext } from "../../../Context/contextMachine";
import { UseFua } from "../../../Context/index";

export function ButtonGroupAlphanumericScreen({ errors, isValid, captchaToken, password, isRecaptchaActive, recaptchaRef, setCaptchaToken }) {
    const showPopUp = () => back()
    const { username } = UseFua();
    const [, send] = useMachineContext();

    const handlePasswordAlphanumeric = () => {
        send("VALIDATEALPHANUMERICKEY", { username, password, captchaToken });
        if (isRecaptchaActive === "true") {
            recaptchaRef.current.reset();
            setCaptchaToken(null)
        }
    }

    return (
        <ButtonGroup>
            <ButtonTemplate
                customStyle={ButtonBackStyle}
                type="button"
                title={"Volver"}
                fuctionButton={showPopUp}
            />
            <ButtonTemplate
                customStyle={
                    !errors.pass && isValid && captchaToken !== null
                        ? ButtonEnableStyle
                        : ButtonDisableStyle
                }
                type={"button"}
                title={"Continuar"}
                disabled={!isValid || captchaToken === null}
                fuctionButton={handlePasswordAlphanumeric}
            />
        </ButtonGroup>
    );
}