import styled from "styled-components";

const Container = styled.div`
  border-radius: 16px;
  width: 556px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  gap: 32px;
  padding: 48px;

  @media screen and (min-width: 280px) and (max-width: 480px) {
    width: 110vw;
  }

  @media screen and (min-width: 380px) and (max-width: 580px) {
    width: 80vw;
  }

  @media screen and (min-width: 580px) and (max-width: 961px) {
    width: 60vw;
  }

  @media screen and (min-width: 320px) and (max-width: 850px) and (orientation: landscape) and (min-aspect-ratio: 13/9) {
    width: 50vw;
  }

  @media screen and (width: 320px) and (height: 568px) {
    width: 97vw;
  }

  @media screen and (width: 568px) and (height: 320px) and (orientation: landscape) {
    width: 70vw;
  }

  @media screen and (width: 600px) and (height: 960px) and (orientation: portrait) {
    width: 80vw;
  }

  @media screen and (width: 883px) and (height: 412px) and (orientation: landscape) {
    width: 50vw;
  }

  @media screen and (min-width: 853px) and (max-width: 1366px) and (orientation: landscape){
    width: 454px;
    margin-top: 30px;
  }
`;
export default Container;