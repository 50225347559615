import React from "react";
import { createPortal } from "react-dom";
import { Overlay, Wrapper, Header, CloseButton, Container, ImageClose, ImageError, CotainerImageError, ContainerDes, TitleMargin, DescriptionTiny, Button } from "../Styles/ModalError/ModalErrorStyle"

function ModalErrorDynamicKey({
  isShowing,
  hide,
  buttonClose,
  redirectUrl,
  title,
  message,
  buttonText
}) {
  let showing = isShowing;
  const redirectionUri = () => {
    showing = false;
    window.location.replace(redirectUrl);
  };

  if (showing) {
    return (createPortal(
      <>
        <Overlay />
        <Wrapper aria-modal aria-hidden tabIndex={-1} role="dialog">
          <Container>
            <Header>
              {buttonClose && (
                <CloseButton
                  type="button"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={hide}
                >
                  <ImageClose />
                </CloseButton>
              )}
            </Header>
            <CotainerImageError>
              <ImageError />
            </CotainerImageError>
            <ContainerDes>
              <TitleMargin>{title}</TitleMargin>
              <DescriptionTiny>{message}</DescriptionTiny>
              <Button onClick={redirectionUri}>{buttonText}</Button>
            </ContainerDes>
          </Container>
        </Wrapper>
      </>,
      document.body
    ));
  }
  return null;
}
export default ModalErrorDynamicKey;
