import React from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";
import closeIcon from "../../Images/closeImageIconBlack.svg";
import IconStatusInfo from "../../Images/IconStatusInfo.svg"

const Overlay = styled.div`
  position: fixed;
  -webkit-box-align: center;
  -webkit-box-pack: center;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(44, 42, 41, 0.8);
  backdrop-filter: blur(2px);
`;

const GridContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Wrapper = styled.div`
  position: fixed;
  -webkit-box-align: center;
  -webkit-box-pack: center;
  top: 50px;
  z-index: 1050;
  width: 400px;
  height: auto;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #ffffff;
  border-radius: 16px;

  /*mobile*/
  @media screen and (min-width: 0px) and (max-width: 575px) {
    width: auto;
    top:  20px;
  }

  @media screen and (min-width: 576px) and (max-width: 767px) {
    width: 340px;
    top: 20px;
  }

  @media screen and (min-width: 0px) and (max-width: 575px) and (orientation: landscape) {
    width: auto;
    position: relative;
    top: 0.5px;
  }

  @media screen and (min-width: 576px) and (max-width: 767px) and (orientation: landscape) {
    width: auto;
    position: relative;
    top: 0.5px;
  }

  /*tablet*/
  @media screen and (min-width: 768px) and (max-width: 840px) and (orientation: landscape) {
    width: auto;
    position: relative;
    top: 0.5px;
  }

  @media screen and (min-width: 841px) and (max-width: 991px) and (orientation: landscape) {
    width: auto;
    position: relative;
  }
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 16px;
`;

const Header = styled.div`
  display: flex;
  justify-content: flex-end;
  box-sizing: content-box;
  padding: 15px 15px 0px 0px;
`;
const CloseButton = styled.button`
  color: #454648;
  background-color: transparent;
  cursor: pointer;
  border: none;
  outline: none;
`;

const ImageClose = styled.div`
  background-image: url(${closeIcon});
  background-repeat: no-repeat;
  background-position: center;
  width: 24px;
  height: 24px;
`;

const IconInfo = styled.div`
  background-image: url(${IconStatusInfo});
  background-repeat: no-repeat;
  background-position: center;
  width: 48px;
  height: 48px;
`;

const ContainerDescription = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 0px 24px 24px 24px;
  gap: 24px;
`;

const Title = styled.div`
  display: flex;
  font-family: "Open Sans";
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  color: rgba(44, 42, 41, 1);
  align-items: center;
  
  /*mobile*/
  @media screen and (min-width: 0px) and (max-width: 767px) {
    font-size: 22px;
  }
`;

const Descirption = styled.div`
  font-family: "Open Sans";
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgba(44, 42, 41, 1);
`;

const ContainerOption = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
`;

const Button = styled.button`
  background-color: rgba(253, 218, 36, 1);
  cursor: pointer;
  border: none;
  width: 352px;
  height: 50px;
  font-family: "Open Sans";
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  color: rgba(44, 42, 41, 1);
  border-radius: 100px;
  box-sizing: border-box;
  padding: 12px 24px 12px 24px;

  /*mobile*/
  @media screen and (min-width: 0px) and (max-width: 767px) {
    width: 308px;
  }
`;

const ContainerSubOption = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  padding-left: 4px;
  gap: 2px;
`;

const Modal = ({
  isShowing,
  hide,
  showOptionSvn
}) =>
  isShowing
    ? createPortal(
      <>
        <Overlay>
          <GridContainer>
            <Wrapper>
              <Container >
                <Header>
                  <CloseButton
                    type="button"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={hide}
                  >
                    <ImageClose />
                  </CloseButton>
                </Header>
                <ContainerDescription>
                  <IconInfo />
                  <Title>¿Qué opción debo elegir?</Title>
                  <ContainerOption>
                    <Descirption style={{ paddingBottom: "16px" }}>Elige la opción que se acople más a lo que deseas hacer.</Descirption>
                    <Descirption>Por ejemplo:</Descirption>
                    <ContainerSubOption>
                      <Descirption >1. Para compras o descargas de certificados de tus cuentas personales elige <b>Bancolombia Personas.</b></Descirption>
                      <Descirption>2. Para compras con el saldo que tienes en A la mano, elige <b>Bancolombia A la mano.</b></Descirption>
                      <Descirption hidden={!showOptionSvn}>3. Para compras por PSE o pagos de nómina, selecciona <b>Bancolombia Negocios.</b></Descirption>
                    </ContainerSubOption>
                  </ContainerOption>
                  <Button onClick={hide}>Entendido</Button>
                </ContainerDescription>

              </Container>
            </Wrapper>
          </GridContainer>
        </Overlay>
      </>,
      document.body
    )
    : null;

export default Modal;