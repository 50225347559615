import styled from "styled-components";

export const SecondaryContainer = styled.div`
  position: relative;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 50% 100%;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 5rem 0rem;
`;
