import styled from "styled-components";

export const ErrorFront = styled.div`
  position: absolute;
  margin: 15.5em auto auto auto;
  color: #fa5e5b;
  font-family: "Open Sans";
  font-size: 12px;
  font-weight: bold;
  letter-spacing: -0.2px;
  line-height: 20px;
`;
