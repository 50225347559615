import getUrlParam, { getValueCookie } from "../Utilities/httpUtils";
import axios from "axios"
import config from "../config";
import { createMachine, assign } from "xstate";
import {
  COOKIE_NAME,
  contentType,
  UNAVAILABILITY_CODES_ERROS,
  MINUS_ONE,
  informationScreen,
  informationNavigator,
  cookieNameCsid
} from "../Constant/Constants";
import { codeDynamicKeyIncorrect, codeDynamicKeyMaxAttemps, sesionError, listCodeErroDynamicKey } from "../Constant/ConstantsDynamicKey";
import { customerFlow } from "../Services/CustomerFlow";
import { validateRedirect } from "../Utilities/GeneralFunctions"

const URLAUTHORIZE = config.url.URLAUTHORIZE;
const URLGETIP = config.url.URLGETIP;
const URLVALIDATEDYNAMICCODE = config.url.URLAUTHFUADYNAMICKEY

const fetchValidateParametersDk = async () => {
  const ip = await fetch(URLGETIP).then(response => response.json());
  return {
    currentIp: ip,
    idClient: getUrlParam("client_id"),
    responseType: getUrlParam("response_type"),
    redirectUri: getUrlParam("redirect_uri"),
    trace: getUrlParam("trace"),
    prompt: getUrlParam("prompt"),
    linkCode: getUrlParam("linkCode"),
    businessPartner: getUrlParam("businessPartner")
  };
};

const validateCookieService = () => async () => {
  const name = COOKIE_NAME + "=";
  const listCookie = document.cookie.split(";");
  for (let ca of listCookie) {
    while (ca.charAt(0) === " ") {
      ca = ca.substring(1);
    }
    if (ca.indexOf(name) === 0) {
      return ca.substring(name.length, ca.length);
    }
  }
  return null;
};

const deleteCookie = async () => {
  document.cookie = COOKIE_NAME + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

const validateAuthorize = async context => {
  const PARAMS = await getBodyUrlauthorize(context);
  if (context.idClient !== "" && context.idClient !== null) {
    const headersAuthorize = { contentType };
    return axios
      .post(URLAUTHORIZE, PARAMS.data, { headersAuthorize })
      .then(response => response.data);
  }
  throw new Error("clientId no especificado");
};

const validateAuthDynamicKey = async (context, event) => {
  const request = await getBodyDynamicKey(context, event);
  const headersValidateDynamicKey = { contentType };
  const res = await axios
    .post(URLVALIDATEDYNAMICCODE, request, { headersValidateDynamicKey })
    .then(response => response.data);
  if (res.errors && res.errors[0].code === sesionError) {
    deleteCookie()
  }
  return res
};

const getBodyUrlauthorize = async context => {
  return {
    data: {
      data: [
        {
          type: "validation",
          id: context.idClient,
          attributes: {
            responseType: context.responseType,
            redirectUri: context.startUrl,
            idClient: context.idClient,
            ipConsumidor: context.currentIp.ip,
            trace: context.trace,
            prompt: context.prompt,
            linkCode: context.linkCode,
            businessPartner: context.businessPartner,
          },
        },
      ],
    },
  };
}

const getBodyDynamicKey = async (context, event) => {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const info = { screen: informationScreen, navigator: informationNavigator, time_zone: timeZone }
  return {
    info: info,
    redirect_uri: context.redirectUri,
    dynamic_key: event.values,
    sso_id: context.sso_id,
    csid: await getValueCookie(cookieNameCsid)
  };
}

const initialContext = {
  idClient: "",
  error: "",
  sso_id: "",
  currentIp: "",
  urlCode: "",
  errorCode: "",
  startUrl: "",
  fuaUrl: "",
  redirectUri: "",
  trace: "",
  deviceType: "",
  hideHeader: true
};

const dynamicKeyMachine = createMachine(
  {
    id: "dynamicKey",
    initial: "validateRedirect",
    context: initialContext,
    states: {
      validateRedirect: {
        invoke: {
          id: "validateRedirectId",
          src: validateRedirect,
          onError: {
            target: "errorData",
          },
          onDone: [
            {
              cond: (_context, event) => event.data.redirectUrl,
              actions: assign({ redirectUrl: (_context, event) => event.data.redirectUrl }),
              target: "redirect",
            },
            {
              target: "validateParameters",
            },
          ],
        },
      },
      validateParameters: {
        invoke: {
          id: "validateParametersId",
          src: fetchValidateParametersDk,
          onDone: {
            target: "validateUrl",
            actions: assign({
              currentIp: (_context, event) => event.data.currentIp,
              idClient: (_context, event) => event.data.idClient,
              responseType: (_context, event) => event.data.responseType,
              startUrl: (_context, event) => event.data.redirectUri,
              redirectUri: (_context, event) => event.data.redirectUri,
              trace: (_context, event) => event.data.trace,
              prompt: (_context, event) => event.data.prompt,
              linkCode: (_context, event) => event.data.linkCode,
              businessPartner: (_context, event) => event.data.businessPartner,
              fuaUrl: (_context, event) => `?response_type=code&client_id=${event.data.idClient}&redirect_uri=${event.data.redirectUri}`
            }),
          },
          onError: {
            target: "errorData"
          },
        },
      },
      validateUrl: {
        invoke: {
          id: "validateUrlParamsId",
          src: validateAuthorize,
          onError: {
            target: "errorData",
            actions: assign({
              error: (_context, event) => event.data,
            }),
          },
          onDone: [
            {
              target: "errorData",
              cond: (_context, event) => event.data.errors,
              actions: assign({
                errorCode: (_context, event) => event.data.errors[0].code,
              }),
            },
            {
              cond: (_context, event) =>
                event.data !== "" && event.data !== null,
              target: ["validateCookie"]
            },
          ],
        },
      },
      validateCookie: {
        invoke: {
          id: "checkCookieId",
          src: validateCookieService,
          onDone: [
            {
              cond: (_context, event) =>
                event.data !== "" && event.data !== null,
              target: "validateDynamicKey",
              actions: assign({
                sso_id: (_context, event) => event.data,
              }),
            },
            {
              target: "errorCookie",
              actions: assign({ errorCode: () => sesionError })
            },
          ],
        },
      },
      validateDynamicKey: {
        initial: "validate",
        states: {
          validate: {
            on: {
              VALIDATEDYNAMICKEY: [
                {
                  target: "validateDynamicCode",
                },
              ],
            },
          },
          validateDynamicCode: {
            invoke: {
              id: "validateAuthDynamicKey",
              src: validateAuthDynamicKey,
              onDone: [
                {
                  cond: (_context, event) => event.data.errors && codeDynamicKeyIncorrect.indexOf(event.data.errors[0].code) !== MINUS_ONE,
                  target: "errorIncorrectKey",
                  actions: assign({ errorCode: (_context, event) => event.data.errors[0].code })
                },
                {
                  cond: (_context, event) => event.data.errors && UNAVAILABILITY_CODES_ERROS.indexOf(event.data.errors[0].code) !== MINUS_ONE,
                  target: "serviceError",
                  actions: assign({ errorCode: (_context, event) => event.data.errors[0].code })
                },
                {
                  cond: (_context, event) => event.data.errors && event.data.errors[0].code === codeDynamicKeyMaxAttemps,
                  target: "errorMaxAttemps",
                  actions: assign({ errorCode: (_context, event) => event.data.errors[0].code })
                },
                {
                  cond: (_context, event) => event.data.errors && event.data.errors[0].code === sesionError,
                  target: "errorSession",
                  actions: assign({ errorCode: (_context, event) => event.data.errors[0].code })
                },
                {
                  cond: (_context, event) => event.data.errors && listCodeErroDynamicKey.indexOf(event.data.errors[0].code) !== MINUS_ONE,
                  target: "errorGeneric",
                  actions: assign({ errorCode: (_context, event) => event.data.errors[0].code })
                },
                {
                  cond: (_context, event) => event.data.errors,
                  target: "serviceError",
                  actions: assign({ errorCode: (_context, event) => event.data.errors[0].code })
                },
                {
                  target: "success",
                  actions: ["redirectToUrl"],
                }
              ],
              onError: [
                {
                  target: "serviceError",
                  actions: assign({ errorCode: (_context, event) => event.data }),
                }
              ],
            },
          },
          errorGeneric: {
            type: "final",
          },
          errorSession: {
            target: "errorCookie",
          },
          errorMaxAttemps: {
            type: "final",
          },
          errorIncorrectKey: {
            on: {
              RETRYAUTH: {
                target: "validateDynamicCode",
              },
            },
            type: "final",
          },
          serviceError: {
            on: {
              RETRYAUTH: {
                target: "validateDynamicCode",
              },
            },
            type: "final",
          },
          success: {
            after: {
              2000: {
                type: "final",
              },
            },
          }
        }
      },
      redirect: {
        type: "final",
        entry: ["redirectToTarget"],
      },
      success: {
        after: {
          2000: {
            type: "final",
          },
        },
      },
      errorData: {
        type: "final",
      },
      errorCookie: {
        type: "final",
      }
    },
  },
  {
    actions: {
      redirectToUrl: assign((_context, event) => {
        const { url } = event.data;
        customerFlow(_context, "0003", "FORM_DYNAMIC_KEY_01")
        const urlRedirect = _context.trace !== null && _context.trace !== "" ? `${url}&trace=${_context.trace}` : url
        window.location.replace(urlRedirect)
      }),
      redirectToTarget: _context => window.location.replace(_context.redirectUri)
    },
  }
);
export default dynamicKeyMachine;
