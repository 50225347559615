import styled from "styled-components";
import back from "../../Images/backIcon.svg";
import exit from "../../Images/ExitIcon.svg";


export const StyledHeader = styled.div`
  width: 100%;
  flex-direction: row;
  background-color: #FFFFFF;
  margin: auto;
  z-index: 1;
  align-items: center;
  justify-content: center;
  display: ${props => props.hide ? 'none' : 'flex'};
`;

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    cursor: pointer;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;

export const ContainerV1 = styled.div`
    display: flex;
    flex-direction: row;
    cursor: pointer;
    justify-content: space-between;
    align-items: center;
`;

export const BackContainer = styled.div`
    visibility: hidden;
   // display: flex; //when the opcion is enabled hidden the line 35 and enabled this one
    flex-direction: row;
    cursor: pointer;
    justify-content: space-between;
    align-items: center;
`;

export const ExitContainer = styled.div`
    display: flex;
    flex-direction: row;
    cursor: pointer;
    justify-content: space-between;
    align-items: center;
`;

export const BackIcon = styled.div`
    height: 24px;
    width: 24px;
    background-image: url(${back});
    background-repeat: no-repeat;
    background-position: center center;
    margin-right: 12px;
`;


export const ExitIcon = styled.div`
    height: 24px;
    width: 24px;
    background-image: url(${exit});
    background-repeat: no-repeat;
    background-position: center center;
    margin-right: 12px;
`;

export const BackText = styled.div`
    display: flex;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;

    @media screen and (max-width: 480px) {
      display: none;
    }
`;

export const ExitText = styled.div`
    display: flex;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
`;

export const Logo = styled.div`
  width: 218px;
  height: 60px;
  background-image: url(${props => props.img});
`;