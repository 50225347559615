import AlertMessage from "../../Components/AlertMessage";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
    USER,
    ENTEROKAY,
    CONTINUE
  } from "../../Constant/Constants";

export function HandlerUserAlphanumericKeyForm({ machine, showAlert, close, urlRedirect}) {
    const isMatchCredentialsError = ["sendCredentials.error", "validateUserDynamicKey.errorIncorrectData", "validateAlphanumericKeyAndTokenSf.errorIncorrectData"]
    const isMatchForm = ["sendCredentials.continueFlow", "validateUserDynamicKey.continueFlow", "validateAlphanumericKeyAndTokenSf.continueFlow"]
    const history = useHistory();
    const flowName = "Autenticacion usuario"
    const [isDataIncorrect, setIsDataIncorrect] = useState(false);

    const handleMatchForm = () => {
        history.push(urlRedirect);
        executeDatalayer(flowName);
    };

    const handleMatchCredentialsError = () => {
        const hasIncorrectData = showAlert && isMatchCredentialsError.some(machine.matches);
        setIsDataIncorrect(hasIncorrectData);
    };

    useEffect(() => {
        if (isMatchForm.some(machine.matches)) {
            handleMatchForm();
        } else {
            handleMatchCredentialsError();
        }
    }, [machine, showAlert]);
      
    return (
        <>
           {isDataIncorrect  && (
            <AlertMessage close={close} title="Datos incorrectos" message="Los datos ingresados no coinciden, inténtalo nuevamente." />
            )}
        </>
    )
}

const executeDatalayer = flowName => {
    try {
      window.dataLayer.push({ nombreflujo: flowName, nombrePaso: USER, event: ENTEROKAY + USER + CONTINUE, }, []);
      return `success datalayer `
    } catch (e) {
      return `error datalayer ${e}`
    }
};