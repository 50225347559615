import styled from "styled-components";
import background from "../Images/trazo-desktop.svg";
import backgroundMobile from "../Images/trazo-mobile.svg";

const MainContent = styled.div`
  padding-top: 5em;
  background-image: url(${background});
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 100%;
  padding-bottom: 5em;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 610px) {
    background-size: contain;
    background-image: url(${backgroundMobile});
  }
`;
export default MainContent;
