import React, { useEffect, useState } from "react";
import { useMachineContext } from "../../Context/contextMachine";
import { setModulus } from "../../Services/bcOaep";
import { options, consumers, EFA002, timeOutCustomerFlow } from "../../Constant/Constants";
import { FormGroup } from "../../Styles/AppAtHandFormStyle/FormGroup"
import { ErrorFront } from "../../Styles/AppAtHandFormStyle/ErrorFrontDocument"
import { ErrorFrontCellphone } from "../../Styles/AppAtHandFormStyle/ErrorFrontCellphone"
import { Formik } from "formik";
import { useHistory } from "react-router-dom";
import { UseFua } from "../../Context/index";
import { errorsOtp, incorrectData, description } from "../../Constant/ConstantsOtp";
import { customerFlow } from "../../Services/CustomerFlow";
import { getTrace } from '../../Utilities/httpUtils'
import Container from "../../Styles/AppAtHandFormStyle/Container";
import ContainerHeader from "../ContainerHeader";
import PrincipalContainer from "../PrincipalContainer"
import MainContent from "../MainContent";
import Input from "../../Styles/AppAtHandFormStyle/Input";
import Footer from "../Footer";
import Loader from "../Loader";
import TitleHeader from "../TitleHeader";
import SelectApp from '../../Styles/AppAtHandFormStyle/SelectApp';
import { validationSchemaAppHand } from "../../Utilities/Regex"
import { ButtonGroupAppAtHand } from "../CustomButtons/ButtonGroupAppAtHand/ButtonGroupAppAtHand"
import { AlertGoOut } from "../AlertGoOut/AlertGoOut"
import HeaderTemplate from "../HeaderTemplate/HeaderTemplate"
import logoHeader from "../../Images/headerIconBancolombia.svg";
import { HandlerAppAtHandForm } from "../../HandlerComponents/HandlerAppAtHandForm/HandlerAppAtHandForm"
import { contextSwitchHandlerBiocatch, back } from "../../Utilities/GeneralFunctions"
import { loadFormPersonalDataDatalayer, clickContinueFormPersonalDataDatalayer } from "../../HandlerComponents/HandlerDatalayers/HandlerDatalayers"

export function AppAtHandForm() {
    const history = useHistory();
    const { setDevice, setCelphoneNumber, setDocument, setTypeDocument } = UseFua();
    const [machine, send] = useMachineContext();
    const [showAlert, setShowAlert] = useState(true);
    const isMatch = ["validateParameters", "validateCookie", "validCookie", "successSSO", "redirect"]
    const [selectedOption, setSelectedOption] = useState(options[0]);
    const [title, setTitle] = useState("");
    const [message, setMessage] = useState("");
    const isMatchLoader = ["successSSO", "redirect", "validateUrl", "validateParameters", "validateOtp.validateRecaptcha"]
    const [showPopUpGoOut, setShowPopUpGoOut] = useState(false);
    const [isButtonBack, setIsButtonBack] = useState(false);
    const isV2 = window.document.URL.includes("v2")

    useEffect(() => {
        contextSwitchHandlerBiocatch("APP_AT_HAND_FORM")
        loadFormPersonalDataDatalayer()
    }, []);

    const close = () => {
        setShowAlert(false);
    };

    useEffect(() => {
        if (incorrectData === machine.context.errorCode || machine.matches("validateOtp.cautionOnlyOneAttempt")) {
            errorsOtp.forEach(element => {
                if (element.code === machine.context.errorCode) {
                    setTitle(element.title)
                    setMessage(element.description)
                }
            });
        }
        if (machine.matches("validateOtp.errorCodeSecurity")) {
            setTitle("Datos Incorrectos")
            setMessage(description)
        }
    }, [machine]);

    useEffect(() => {
        customerFlow(machine.context, "0002", "FORM_TYPE_DOCUMENT_02")
    }, []);

    setTimeout(() => {
        if (consumers.includes(machine.context.idClient)) {
            window.location.replace(machine.context.redirectUri + EFA002 + getTrace(machine.context));
        }
    }, timeOutCustomerFlow);

    const showPopUp = () => {
        setShowPopUpGoOut(true)
        setIsButtonBack(false)
    };

    return (
        <PrincipalContainer>
            <HeaderTemplate logoHeader={logoHeader} isV2={isV2} exitFunction={showPopUp} backFunction={back} marginBottom='45px' marginBottomMobile='45px' />
            {showPopUpGoOut && <AlertGoOut machine={machine} setShowPopUpGoOut={setShowPopUpGoOut} isButtonBack={isButtonBack} isV2={isV2} />}
            <MainContent>
                <TitleHeader title="Te damos la bienvenida" widthTitle="458px" heightTitle="30px" topTittle="30px" />
                {(isMatchLoader.some(machine.matches)) && (<Loader />)}
                <HandlerAppAtHandForm machine={machine} showAlert={showAlert} close={close} title={title} message={message} />
                <Container>
                    <ContainerHeader subTitle="Ingresa tus datos para continuar" widthTitle="65%" widthSubTitle="65%"
                        topM="20px"
                        icon={false}
                        heightL="10%"
                    />
                    <Formik
                        initialValues={{ documentNumber: 0, celphoneNumber: 0 }}
                        validationSchema={validationSchemaAppHand}
                        onSubmit={values => {
                            clickContinueFormPersonalDataDatalayer()
                            send("RETRYAUTH", { documentNumber: values.documentNumber, documentType: selectedOption.value })
                            setDocument(values.documentNumber)
                            setCelphoneNumber(values.celphoneNumber)
                            setModulus(machine.context.modulus);
                            setTypeDocument(selectedOption.value)
                            setDevice();
                            send("VALIDATERECAPTCHA", { documentNumber: values.documentNumber, documentType: selectedOption.value })
                        }}
                    >
                        {({ errors, touched, handleSubmit, isValid }) => (
                            <>
                                <FormGroup onSubmit={handleSubmit}>
                                    <SelectApp options={options} selectedOption={selectedOption} onChange={setSelectedOption} />
                                    <Input type="tel" maxLength={20} name="documentNumber" autoCorrect="off" autoComplete="off" placeholder="" label="Número del documento"
                                        valid={touched.documentNumber && !errors.documentNumber} error={touched.documentNumber && errors.documentNumber} top="0px"
                                    />
                                    {(errors.documentNumber) ? <ErrorFront type="Doc">{errors.documentNumber}</ErrorFront> : null}

                                    <Input type="tel" maxLength={10} name="celphoneNumber" autoCorrect="off" autoComplete="off" placeholder="" label="Número de celular"
                                        valid={touched.celphoneNumber && !errors.celphoneNumber} error={touched.celphoneNumber && errors.celphoneNumber}
                                        top={touched.documentNumber && errors.documentNumber ? "20px" : "0px"}
                                        disabled={isMatch.some(machine.matches)}
                                    />

                                    {(touched.celphoneNumber && errors.celphoneNumber) ? <ErrorFrontCellphone type="Cel">{errors.celphoneNumber}</ErrorFrontCellphone> : null}
                                    <ButtonGroupAppAtHand isValid={isValid} isMatch={isMatch} machine={machine} setShowPopUpGoOut={setShowPopUpGoOut} setIsButtonBack={setIsButtonBack} />
                                </FormGroup>
                            </>
                        )}
                    </Formik>
                    {machine.matches("errorForm") &&
                        (() => {
                            history.push("/unavailability");
                        })()}
                </Container>
            </MainContent>
            <Footer top="50px" topMovil="560px" />
        </PrincipalContainer>
    );
}
