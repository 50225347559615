import { EFA004, userFormPath } from "../../Constant/Constants";
import AlertMessageDynamicKey from "../../Components/AlertMessageDynamicKey"
import ModalErrorDynamicKey from "../../Components/ModalErrorDynamicKey"
import { getTrace } from '../../Utilities/httpUtils'
import ModalError from "../../Components/ModalError"
import RedirectionMessage from "../../Components/RedirectionMessage";


export function HandlerDynamicKeyFirstFactor({ machine, showAlert, customMessage, setShowAlert, customTitle,  history }) {
    const isMatchUnavailability = ["validateDynamicKey.serviceError", "validateUserDynamicKey.errorService", "errorData"]
    const isMatchRedirectMessage = ["validateDynamicKey.success", "validateUserDynamicKey.success"]
    const isMatchErrorCookieSession = ["errorCookie", "validateDynamicKey.errorSession"]
    const isMatchErrorMaxAttempts = ["validateDynamicKey.errorMaxAttemps", "validateUserDynamicKey.errorMaxtries"]
    const close = () => {
        setShowAlert(false);
    };
    return (
        <>
            {(isMatchUnavailability.some(machine.matches)) && (() => { history.push("/unavailability"); })()}
            {(isMatchRedirectMessage.some(machine.matches)) && (<RedirectionMessage />)}

            {(machine.matches("validateDynamicKey.errorIncorrectKey") && showAlert) && (
                <AlertMessageDynamicKey close={close} customMessage={customMessage} />
            )}
            {(isMatchErrorCookieSession.some(machine.matches)) && (
                <ModalErrorDynamicKey isShowing buttonClose={false} redirectUrl={userFormPath + machine.context.fuaUrl}
                    title={customTitle}
                    message={customMessage}
                    buttonText="Entendido"
                />
            )}
            {(isMatchErrorMaxAttempts.some(machine.matches)) && (
                <ModalErrorDynamicKey isShowing buttonClose={false} redirectUrl={machine.context.redirectUri + EFA004 + getTrace(machine.context)}
                    title={customTitle}
                    message={customMessage}
                    buttonText="Entendido"
                />
            )}
            {machine.matches("validateUserDynamicKey.errorIncorrectData") && (
                () => {
                    history.push("/login" + machine.context.startUrl.split("login")[1]);
                }
            )()}
            {machine.matches("validateUserDynamicKey.errorScore") && (
                <ModalError isShowing topM="50%" top="20%" buttonClose={false} startUrl={machine.context.startUrl}
                    title="No pudimos validar que eres tú"
                    message="Intentémoslo nuevamente."
                    titleBotton="Regresar al sitio"
                />
            )}
        </>
    )
}