import React from "react";
import styled from "styled-components";
import iconAdmiration from "../Images/iconAdmiration.svg";
import image from "../Images/errorImage.svg";

const Container = styled.div`
  width: 60%;
  height: 56px;
  top: 5%;
  display: flex;
  position: absolute;
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  background-color: #fff;
  margin: auto;

  @media screen and (min-width: 280px) and (max-width: 480px) {
    width: 90%;
    height: auto;
    z-index: 100;
  }

  @media screen and (min-width: 481px) and (max-width: 961px) {
    width: 90%;
    height: auto;
    z-index: 100;
  }

  @media screen and (min-width: 961px) and (max-width: 1024px) {
    width: 90%;
    height: auto;
    z-index: 100;
  }
`;

const ContentImage = styled.div`
  width: 48px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ff7f41;

  @media screen and (min-width: 280px) and (max-width: 480px) {
    width: 20%;
    height: auto;
  }

  @media screen and (min-width: 481px) and (max-width: 961px) {
    width: 15%;

    height: auto;
  }

  @media screen and (min-width: 961px) and (max-width: 1024px) {
    width: 15%;
    height: auto;
  }
`;

const Image = styled.div`
  width: 33px;
  height: 33px;
  background-image: url(${iconAdmiration});
  background-repeat: no-repeat;
  background-position: center;
`;
const CloseImage = styled.div`
  background-image: url(${image});
  background-repeat: no-repeat;
  background-position: center;
  width: 16px;
  height: 16px;
  border: none;
`;
const CloseButton = styled.button`
  margin-left: auto;
  background-color: transparent;
  color: #454648;
  cursor: pointer;
  border: none;
  padding: 2%;
`;

const Text = styled.p`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: -0.3px;
  color: #292929;
  padding-left: 10px;

  /*@media screen and (min-width: 280px) and (max-width: 480px) {
  }*/
`;

function AlertMessageDynamicKey({ close, customMessage }) {  
  return (
    <>
      <Container>
        <ContentImage>
          <Image />
        </ContentImage>
        <Text>
        {customMessage}
        </Text>
        <CloseButton aria-label="Close" onClick={close}>
          <CloseImage src={image} />
        </CloseButton>
      </Container>
    </>
  );
}
export default AlertMessageDynamicKey;
