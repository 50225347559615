export const titleErrorDynamicKey = "Algo salió mal";
export const messageSystemError =
  "Lo sentimos, tenemos inconvenientes con nuestros sistemas, "
  + "nuestro equipo se encuentra trabajando para brindarte una solución. "
  + "Intenta más tarde."
export const codeNoValid = "Clave dinámica incorrecta. Verifica e intenta nuevamente.";
export const messageInfoIncomplete = "Algo salió mal. Parece que la información que enviaste no llegó completa, intentalo nuevamente."
export const messageBlockOrRegistration =
  "Lo sentimos, si recientemente realizaste la inscripción al servicio de Clave Dinámica, "
  + "recuerda que en transcurso de dos horas te notificaremos al celular o correo electrónico el estado de tu Clave Dinámica. "
  + "Si recientemente bloqueaste la Clave Dinámica, para activarla dirígete a un Cajero Automático y cambia la Clave Principal."
export const maxAttemps = "Lo sentimos, superaste el número de intentos permitidos, y la clave dinámica esta bloqueada. Quedará activa nuevamente en 2 horas.";
export const messageSystemNewAuthentication = "Error en la sesión, es necesario que realices una nueva autenticación.";
export const incorrectData = "Los datos ingresados no coinciden, inténtalo nuevamente.";
export const noEnrollment = "Para continuar con la transacción consulta y administra tu clave dinámica en la APP Personas.";
export const errorsDynamicKey = [
  {
    code: "1013",
    title: titleErrorDynamicKey,
    description: "Parece que la información que enviaste no llegó completa, intentalo nuevamente."
  },
  {
    code: "1021",
    title: titleErrorDynamicKey,
    description: "Lo sentimos, tu clave dinámica se encuentra bloqueada. Intentalo más tarde."
  },
  {
    code: "1022",
    title: titleErrorDynamicKey,
    description: "¡Lo sentimos! Por tu seguridad, la Clave Dinámica fue bloqueada. "
      + "Activala cambiando la Clave Principal en un Cajero Automático o Corresponsal Bancario. "
      + "Si tienes Tarjeta Crédito Bancolombia, ingresa a la Sucursal Virtual Personas y en el costado inferior derecho "
      + "entra a la opción de desbloqueo por Wompi, sigue las instrucciones y tu clave se activará en máximo 2 horas"
  },
  {
    code: "1023",
    title: titleErrorDynamicKey,
    description: messageBlockOrRegistration
  },
  {
    code: "400",
    title: titleErrorDynamicKey,
    description: "Lo sentimos,tenemos inconvenientes con nuestros sistemas, nuestro equipo se encuentra trabajando para brindarte una solución. Intenta más tarde."
  },
  {
    code: "500",
    title: titleErrorDynamicKey,
    description: "Lo sentimos. Inténtalo nuevamente. Se ha superado el tiempo de inactividad permitido para navergar en la aplicación."
  },
  {
    code: "902",
    title: titleErrorDynamicKey,
    description: codeNoValid
  },
  {
    code: "903",
    title: titleErrorDynamicKey,
    description: codeNoValid
  },
  {
    code: "9993",
    title: titleErrorDynamicKey,
    description: codeNoValid
  },
  {
    code: "942",
    title: titleErrorDynamicKey,
    description: messageBlockOrRegistration
  },
  {
    code: "98",
    title: titleErrorDynamicKey,
    description: messageInfoIncomplete
  },
  {
    code: "99",
    title: titleErrorDynamicKey,
    description: messageSystemError
  },
  {
    code: "9999",
    title: titleErrorDynamicKey,
    description: messageSystemError
  },
  {
    code: "2225",
    title: titleErrorDynamicKey,
    description: noEnrollment
  },
  {
    code: "ER404-01",
    title: titleErrorDynamicKey,
    description: messageSystemError
  },
  {
    code: "ER404-06",
    title: titleErrorDynamicKey,
    description: messageSystemError
  },
  {
    code: "SA404",
    title: titleErrorDynamicKey,
    description: messageSystemError
  },
  {
    code: "SA500",
    title: titleErrorDynamicKey,
    description: messageSystemError
  },
  {
    code: "SA504",
    title: titleErrorDynamicKey,
    description: messageSystemError
  },
  {
    code: "97",
    title: titleErrorDynamicKey,
    description: messageBlockOrRegistration
  },
  {
    code: "SA400",
    title: titleErrorDynamicKey,
    description: messageSystemError
  },
  {
    code: "9994",
    title: titleErrorDynamicKey,
    description: messageInfoIncomplete
  },
  {
    code: "802",
    title: titleErrorDynamicKey,
    description: codeNoValid
  },
  {
    code: "811",
    title: titleErrorDynamicKey,
    description: messageBlockOrRegistration
  },
  {
    code: "0999",
    title: titleErrorDynamicKey,
    description: messageSystemNewAuthentication
  },
  {
    code: "ER404-19",
    title: titleErrorDynamicKey,
    description: maxAttemps
  },
  {
    code: "ER500-01",
    title: titleErrorDynamicKey,
    description: incorrectData
  },
  {
    code: "BP2225",
    title: titleErrorDynamicKey,
    description: noEnrollment
  }
];
export const codeDynamicKeyIncorrect = ["902", "903", "802", "ER500-01","BP2225","9993"]
export const codeDynamicKeyRedirectScreenFua = ["1013", "98", "1021", "9994", "97", "811"]
export const codeDynamicKeyMaxAttemps = "ER404-19"
export const sesionError = "0999"
export const timeExpirationDynamicKey = 180000;
export const listCodeErroDynamicKey = [
  "1013",
  "1021",
  "1022",
  "1023",
  "400",
  "500",
  "902",
  "903",
  "942",
  "98",
  "99",
  "9999",
  "2225",
  "ER404-01",
  "ER404-06",
  "ER404-19",
  "SA404",
  "SA500",
  "SA504",
  "97",
  "SA400",
  "9994",
  "0999",
  "802",
  "ER500-01",
  "BP2225",
  "9993"
]
