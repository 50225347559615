import React from "react";
import { ButtonGroup } from "../../../Styles/PasswordFormStyle/ButtonGroup";
import ButtonTemplate from "../../ButtonTemplate/ButtonTemplate";
import {
  ButtonEnableStyle,
  ButtonDisableStyle,
  ButtonBackStyle,
} from "../../ButtonTemplate/ButtonTemplateStyle";
import { replaceUrl } from "../../../Utilities/GeneralFunctions"

function ButtonGroupToken({ machine, validateForm, isActiveButton}) {
  const goTo = () => replaceUrl(machine.context.startUrl)
  return (
    <>
      <ButtonGroup >
        <ButtonTemplate
            customStyle={ButtonBackStyle}
            type="button"
            title={"Volver"}
            fuctionButton={goTo}
        />
        <ButtonTemplate
            customStyle={isActiveButton ? ButtonEnableStyle : ButtonDisableStyle}
            type="submit"
            fuctionButton={validateForm}
            title={"Continuar"}
            disabled={!isActiveButton}
        />
      </ButtonGroup>
    </>
  );
}

export default ButtonGroupToken;