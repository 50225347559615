import styled from "styled-components";
import closeIcon from "../../Images/closeIcon.svg";

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(19.028px);
`;

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow-x: auto;
  overflow-y: auto;
  outline: 0;
`;
export const Header = styled.div`
  width: 100%;  
  position: absolute;
  display: flex;
  justify-content: flex-end;
`;

export const CloseButton = styled.button`
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1;
  color: #454648;
  background-color: transparent;
  cursor: pointer;
  border: none;
  outline: none;
  align-self: flex-end;
  margin-top: 20px;
  margin-right: 20px;
`;

export const Container = styled.div`
  display: flex;
  margin: 2rem auto;
  position: relative;
  flex-direction: column;
  align-content: center;
  width: 600px;
  height: ${props => props.height};
  top: 219px;
  background: #FFFFFF;
  box-shadow: 0px 10px 16px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  
  @media screen and (min-width: 375px) and (max-width: 974px) and (orientation: landscape) {
    top: auto;
  }

  @media screen and (min-width: 280px) and (max-width: 480px) {
    width:  ${props => props.widthM || "355px"};
    height: ${props => props.heightM || "200px"}} ;
  }

  @media screen and (min-width: 280px) and (max-width: 480px) {
    width:  ${props => props.widthM || "340px"};
    height: ${props => props.heightM};
  }

  @media screen and (min-width: 481px) and (max-width: 600px) {
    width:  ${props => props.widthM || "480px"};
    height: ${props => props.heightM};
  }
`;

export const ImageClose = styled.div`
  background-image: url(${closeIcon});
  background-repeat: no-repeat;
  background-position: center;
  width: 16px;
  height: 16px;
  padding: 5px;
`;

export const IconImage = styled.div`
  margin-top: 32px;
  background-image: url(${props => props.iconoImage});
  background-repeat: no-repeat;
  display: flex;
  width: 48px;
  height: ${props => props.heightIcon || "80px"};
`;

export const CotainerImageError = styled.div`
  display: flex;
  justify-content: center;
  left: 200px;  
  @media screen and (min-width: 280px) and (max-width: 480px) {
    left: 0px;
  }
`;


export const ContainerDes = styled.div`
  display: flex;
  flex-direction: column;
  order: 3;
  flex-grow: 0;
  justify-content: center;
  align-items: center;
`;

export const Title = styled.h4`
  color: #000000;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 26px;
  text-align: center;
  letter-spacing: -0.45px;
  margin: 1rem 0;
`;

export const Description = styled.div`
  color: #2C2A29;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  letter-spacing: -0.3px;
  margin: 0 1em;
  line-height: 24px;
`;

export const TitleMargin = styled.h4`
  color: #000000;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 26px;
  text-align: center;
  letter-spacing: -0.45px;
  margin: 1rem 0;
`;

export const DescriptionTiny = styled.div`
  color: #000000;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  letter-spacing: -0.3px;
  padding-bottom: 2em;
  margin: 0 1em;
`;

export const Button = styled.button`
  background: #FDDA24;
  border: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 201px;
  height: 38px;
  position: relative;
  border-radius: 100px;
  font-family: "Open Sans";
  font-weight: 600;
  font-size: 16px;
  outline: none;
  top: ${props => props.hiddenAditionalDescription ? "25px": "5px"};
  padding: 9px 40px;
  gap: 8px;
`;

export const Label = styled.label`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: -0.3px;
  color: #2C2A29;
`;

export const AdditionalDescription = styled.div`
  color: #000000;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  text-align: center;
  letter-spacing: -0.3px;
  padding-bottom: 2em;
  margin: 0 1em;
  padding-top: 1em;
`;