import { object, string } from 'yup';
import { EIGHT, TWENTY, numberCharactersAlphanumericKey} from "../Constant/Constants";

const requiredField = "El campo es requerido"

export const validationSchema = object().shape({
    user: string()
        .min(EIGHT, "Debe tener mínimo 8 caracteres")
        .max(TWENTY, "Debe tener máximo 20 caracteres")
        .matches(
            /^(?!.*[\.\-\_]{2,})^[a-zA-Z0-9ñÑ]{3,24}$/,
            "No debe contener caracteres especiales"
        )
        .matches(/[a-zA-ZñÑ]/, "Caracteres no válidos, Intenta de nuevo")
        .required(requiredField),
});

export const validationSchemaAppHand = object().shape({
    documentNumber: string().matches(/^[0-9]{5,20}$/, "Debe ser numérico, máximo 20 caracteres").typeError("Debe contener solo números").required("El campo es requerido, debe contener solo números"),
    celphoneNumber: string().matches(/^[0-9]{10,10}$/, "Debe ser numérico, máximo 10 caracteres").required(requiredField).trim(),
});

export const validationSchemaAlphanumeric = object().shape({
    pass: string()
        .min(EIGHT, "Debe tener mínimo 8 caracteres")
        .max(numberCharactersAlphanumericKey, "Debe tener máximo 64 caracteres")
        .matches(/[ñA-Za-zÑ]/, "Debe contener al menos una letra")
        .matches(/[0-9]/, "Debe contener al menos un número")
        .matches(/[\.\-\_\,\'\?\{\}\!\¿\¡\#\$\&\/\=\*\:\,]/, "Debe contener al menos una caracter especial excepto un pipe |")
        .required(requiredField),
});