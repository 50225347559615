import styled from "styled-components";

export const Description = styled.div`
  display: flex;
  width: 85%;
  color: #292929;
  font-family: "Open Sans";
  font-size: 16px;
  letter-spacing: -0.3px;
  line-height: 24px;
  text-align: center;
  margin-bottom: 15px;
  position: relative;
  justify-content: center;
`;