import ButtonTemplate from "../../ButtonTemplate/ButtonTemplate";
import {
    ButtonEnableStyle,
    ButtonDisableStyle,
    ButtonBackStyle,
} from "../../ButtonTemplate/ButtonTemplateStyle";
import { ButtonGroup } from "../../../Styles/UserFormStyle/ButtonGroup"
import { useHistory } from "react-router";
import { clickBackFormPasswordDynamicKeyDatalayer } from "../../../HandlerComponents/HandlerDatalayers/HandlerDatalayers"
export function ButtonGroupDynamicKey({ machine, activeButton, validateForm }) {
    const history = useHistory();
    const urlBack = machine.context.startUrl.split("authorize")
    const goTo = () => {
        history.push(`/login/oauth/authorize${urlBack[1]}`)
        clickBackFormPasswordDynamicKeyDatalayer()
    }
    return (
        <ButtonGroup>
            <ButtonTemplate
                customStyle={ButtonBackStyle}
                type="button"
                title={"Volver"}
                fuctionButton={goTo}
            />
            <ButtonTemplate
                customStyle={activeButton ? ButtonEnableStyle : ButtonDisableStyle}
                fuctionButton={validateForm}
                title={"Continuar"}
                disabled={!activeButton}
            />
        </ButtonGroup>
    );
}
