import{ ButtonTemplateStyle, Label} from './ButtonTemplateStyle'
import React from 'react';


function ButtonTemplate({ sizeWith, sizeHeight, sizeHeightM, customStyle, type, title, fuctionButton, disabled, labelStyle, id }) {

    const buttonRef = React.createRef();

    return (
        <ButtonTemplateStyle
            sizeWith={sizeWith}
            sizeHeight={sizeHeight}
            sizeHeightM={sizeHeightM}
            customStyle={customStyle}
            type={type}
            disabled={disabled}
            onClick={fuctionButton}  
            ref={buttonRef}
            id={id}><Label onClick={() => fuctionButton ? null : buttonRef.current.click()} labelStyle={labelStyle}>{title}</Label></ButtonTemplateStyle>
    );
}
export default ButtonTemplate;
