import React, { useEffect } from "react";
import PropTypes from "prop-types";
import image from "../Images/errorImage.svg";
import { Container, ContentImage, Image, CloseImage, CloseButton, ContainerDes, Title, Text } from "../Styles/AlertMessageStyle/AlertMessageStyle"
import { errorModalDataIncorrect } from "../HandlerComponents/HandlerDatalayers/HandlerDatalayers"

function AlertMessage({ close, title, message, flowName }) {
  useEffect(() => {
    errorModalDataIncorrect(flowName)
  }, []);
  return (
    <>
      <Container>
        <ContentImage>
          <Image />
        </ContentImage>
        <ContainerDes>
          <Title>{title}</Title>
          <Text>
            {message}
          </Text>
        </ContainerDes>
        <CloseButton aria-label="Close" onClick={close}>
          <CloseImage src={image} />
        </CloseButton>
      </Container>
    </>
  );
}
AlertMessage.propTypes = {
  close: PropTypes.func.isRequired,
};
export default AlertMessage;
