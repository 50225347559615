import React from "react";
import PropTypes from "prop-types";
import { Title, SubTitle } from "./Title";


function TitleHeader({
  title,
  subTitle,
}) {
  return (
    <>
      <Title>{title}</Title>
      <SubTitle >{subTitle}</SubTitle>
    </>
  );
}

TitleHeader.propTypes = {
  title: PropTypes.string.isRequired,
};

export default TitleHeader;
