import ButtonTemplate from "../../ButtonTemplate/ButtonTemplate";
import {
    OnlyButtonEnableStyle,
    OnlyButtonDisableStyle
} from "../../ButtonTemplate/ButtonTemplateStyle";
import { OnlyButtonGroup } from "../../../Styles/DynamicKeyFirstFactor/DynamicKeyFirstFactorStyle";

export function ButtonGroupDynamicKeyFirstFactor({ activeButton, validateForm }) {
    return (
        <>
            <OnlyButtonGroup>
                <ButtonTemplate
                    customStyle={activeButton ? OnlyButtonEnableStyle : OnlyButtonDisableStyle}
                    type="submit"
                    title={"Continuar"}
                    disabled={!activeButton}
                    fuctionButton={validateForm}
                />
            </OnlyButtonGroup>
        </>
    );
}