import React, { useEffect } from "react";
import styled from "styled-components";
import { consumers, EFA003, TIME_OUT_REDIRETION, } from "../Constant/Constants";
import { useMachineContext } from "../Context/contextMachine";
import alertIcon from "../Images/alertIcon.svg";
import Button from "./Button";
import { getTrace } from '../Utilities/httpUtils'
import { errorModalBlockedAccess } from "../HandlerComponents/HandlerDatalayers/HandlerDatalayers"

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  font-family: "Open Sans";
  font-size: 15px;
  color: #454648;
  position: relative;
  top: 20px;
`;
const Title = styled.div`
  display: flex;
  font-size: 24px;
  font-weight: bold;
  color: #292929;
  font-family: "Nunito";
  margin-bottom: 10px;
`;
const Description = styled.div`
  display: flex;
  position: relative;
  top: 20px;
  height: 72px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  text-align: center;
  letter-spacing: -0.003em;

  color: #292929;
`;
const StyledIcon = styled.div`
  background-image: url(${alertIcon});
  background-repeat: no-repeat;
  background-position: center;
  width: 48px !important;
  height: 48px;
  position: relative;
  text-align: center;
  color: #ffcf01;
  padding: 10px;
`;

function UserBlock({ flowName }) {
  const [machine, send] = useMachineContext();
  useEffect(() => {
    errorModalBlockedAccess(flowName)
  }, []);

  if (consumers.includes(machine.context.idClient)) {
    const redirection = machine.context.redirectUri + EFA003 + getTrace(machine.context);
    setTimeout(() => {
      window.location.replace(redirection);
    }, TIME_OUT_REDIRETION);
  }

  const backFunction = () => {
    send("RETRYUSER");
    window.history.back()
  };
  return (
    <Container>
      <StyledIcon />
      <Title>Acceso bloqueado</Title>
      <Description>Inténtalo nuevamente en 24 horas.</Description>
      <Button
        typeButton="primary"
        width="45%"
        height="38px"
        top="0%"
        onClick={backFunction}
      >
        ENTENDIDO
      </Button>
    </Container>
  );
}

export default UserBlock;
