import React, { useState, useRef, useEffect } from "react";
import { useMachineContext } from "../../Context/contextMachine";
import { Container } from "../../Styles/SecurityCodeFormStyle/Container";
import ContainerHeader from "../ContainerHeader";
import Footer from "../Footer";
import MainContent from "../MainContent";
import PrincipalContainer from "../PrincipalContainer";
import OtpInputDynamicKey from "../OtpInputDynamicKey";
import config from "../../config";
import TitleHeader from "../TitleHeader";
import { FormGroup } from "../../Styles/PasswordFormStyle/FormGroup"
import { ErrorFront } from "../../Styles/SecurityCodeFormStyle/ErrorFront"
import ReCAPTCHA from "react-google-recaptcha-enterprise";
import { ContainerRecaptcha } from "../../Styles/SecurityCodeFormStyle/ContainerRecaptcha"
import { UseFua } from "../../Context/index";
import { useHistory } from 'react-router';
import { errosCodeSecurity, errorsOtp } from "../../Constant/ConstantsOtp";
import { timeOutCustomerFlow, consumers, EFA002 } from "../../Constant/Constants";
import { customerFlow } from "../../Services/CustomerFlow";
import { getTrace } from '../../Utilities/httpUtils'
import { ButtonGroupSecurityCodeForm } from "../CustomButtons/ButtonGroupSecurityCodeForm/ButtonGroupSecurityCodeForm"
import { HandlerSecurityCodeForm } from "../../HandlerComponents/HandlerSecurityCodeForm/HandlerSecurityCodeForm"
import { AlertGoOut } from "../AlertGoOut/AlertGoOut"
import HeaderTemplate from "../HeaderTemplate/HeaderTemplate"
import logoHeader from "../../Images/headerIconBancolombia.svg";
import { InstructionSecurityCodeV2 } from "../InstructionSecurityCode/InstructionSecurityCodeV2"
import { contextSwitchHandlerBiocatch, back } from "../../Utilities/GeneralFunctions"
import { loadFormSecurityCodeDatalayer, clickContinueFormSecurityCodeDatalayer } from "../../HandlerComponents/HandlerDatalayers/HandlerDatalayers"

export function SecurityCodeForm() {
  const [machine, send] = useMachineContext();
  const [error, setError] = useState("");
  const [activeButton, setActiveButton] = useState(false);
  const [values, setvalues] = useState();
  const [incorrectDynamicKey, setIncorrectDynamicKey] = useState(false)
  const recaptchaRef = useRef();
  const [captchaToken, setCaptchaToken] = useState(null);
  const { document, celphoneNumber, typeDocument } = UseFua();
  const urlBack = machine.context.startUrl
  const history = useHistory();
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [timeExpire, setTimeExpire] = useState(false);
  const [retryAuth, setRetryAuth] = useState(false);
  const isRecaptchaActive = config.recaptcha.RECAPTCHA_OTP_STATUS;
  const isMatch = ["errorForm", "validateOtp.error", "validateOtp.unknowErrror"]
  const isMatchAlertIncorrectData = ["validateOtp.errorIncorrectData", "validateOtp.errorCodeSecurity", "validateOtp.cautionOnlyOneAttempt"]
  const isMatchRedirectMessage = ["validateOtp.success", "validateOtp.redirect"]
  const conditionReCaptchaToken = captchaToken !== null && activeButton
  const [showPopUpGoOut, setShowPopUpGoOut] = useState(false);
  const isV2 = urlBack.includes("v2")

  const retryAuthFuc = () => {
    if (retryAuth) {
      send("RETRYAUTH");
      setRetryAuth(false)
    }
  }

  useEffect(() => {
    contextSwitchHandlerBiocatch("SECURITY_CODE_FORM")
    loadFormSecurityCodeDatalayer()
  }, []);

  useEffect(() => {
    if (!machine.context.recapvisible) {
      getRecaptchaToken()
    }
  }, [machine.context.recapvisible]);

  useEffect(() => {
    if (isRecaptchaActive === "false") {
      handledSubmit("");
    }
  }, [isRecaptchaActive, captchaToken]);

  const handledSubmit = value => setCaptchaToken(value)

  const validateForm = () => {
    const otp = values;
    clickContinueFormSecurityCodeDatalayer()
    retryAuthFuc()
    send("VALIDATEOTP", { document, celphoneNumber, typeDocument, otp, captchaToken });
    if (isRecaptchaActive === "true") {
      recaptchaRef.current.reset()
    }
    setCaptchaToken(null)
  };

  useEffect(() => {
    if (errosCodeSecurity.includes(machine.context.errorCode) || machine.matches("validateOtp.errorAttemptsOvercome")) {
      setIncorrectDynamicKey(true)
      setRetryAuth(true)
      errorsOtp.forEach(element => {
        if (element.code === machine.context.errorCode) {
          setTitle(element.title)
          setMessage(element.description)
        }
      });
    } else if (timeExpire) {
      setTitle("Superaste el tiempo de inactividad")
      setMessage("Vuelve a ingresar e intenta nuevamente.")
    }
  }, [timeExpire, machine]);

  useEffect(() => {
    customerFlow(machine.context, "0002", "FORM_SECURITY_CODE_03")
  }, []);

  setTimeout(() => {
    setTimeExpire(true)
    if (consumers.includes(machine.context.idClient)) {
      window.location.replace(machine.context.redirectUri + EFA002 + getTrace(machine.context));
    }
  }, timeOutCustomerFlow);

  const showPopUp = () => {
    setShowPopUpGoOut(true)
  };

  const getRecaptchaToken = async () => {
    const token = await recaptchaRef.current.executeAsync();
    handledSubmit(token)
  }

  return (
    <PrincipalContainer>
      <HeaderTemplate logoHeader={logoHeader} isV2={isV2} exitFunction={showPopUp} backFunction={back} marginBottom='45px' marginBottomMobile='45px' />
      {showPopUpGoOut && <AlertGoOut machine={machine} setShowPopUpGoOut={setShowPopUpGoOut} isButtonBack={false} isV2={isV2} />}
      <MainContent>
        <TitleHeader title="Código de seguridad" widthTitle="454px" heightTitle="30px" topTittle="5px" />
        {isMatchAlertIncorrectData.some(machine.matches) && (() => { history.push("/login" + urlBack.split("login")[1]) })()}
        <Container>
          <ContainerHeader
            subTitle="Genéralo desde tu app e ingrésalo a continuación"
            topSubTittle="40px"
            widthTitle="65%"
            widthSubTitle="65%"
            topM="20px"
            icon
            heightL="10%"
          />
          <FormGroup id="formGroup">
            <OtpInputDynamicKey
              setvalues={setvalues}
              error={error}
              setError={setError}
              setActiveButton={setActiveButton}
              setIncorrectDynamicKey={setIncorrectDynamicKey}
              incorrectDynamicKey={incorrectDynamicKey}
              top="-60px"
              type="securityCode"
            />
            {error && <ErrorFront error={error} topM="30px">{error}</ErrorFront>}
            {isRecaptchaActive === "true" && (
              <ContainerRecaptcha>
                {machine.context.recapvisible && (
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey={config.recaptcha.RECAPTCHA_KEY}
                    onChange={handledSubmit}
                  />
                )}
                {!machine.context.recapvisible && (
                  <ReCAPTCHA
                    size="invisible"
                    ref={recaptchaRef}
                    sitekey={config.recaptcha.RECAPTCHA_KEY_INVISIBLE}
                  />
                )}
              </ContainerRecaptcha>
            )}
            <ButtonGroupSecurityCodeForm conditionReCaptchaToken={conditionReCaptchaToken} validateForm={validateForm} />
          </FormGroup>
          <HandlerSecurityCodeForm machine={machine} timeExpire={timeExpire} title={title} message={message} isMatchRedirectMessage={isMatchRedirectMessage} />
          {isMatch.some(machine.matches) && (() => { history.push("/unavailability"); })()}
        </Container>
        <InstructionSecurityCodeV2 />
      </MainContent>
      <Footer top="0px" topMovil="100%" />
    </PrincipalContainer>
  );
}
