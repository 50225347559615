import axios from "axios";
import getUrlParam, { getValueCookie } from "../Utilities/httpUtils";
import config from "../config";
import { createMachine, assign } from "xstate";
import { contentType, MINUS_ONE, UNAVAILABILITY_CODES_ERROS, unknowErrror, scoreRecaptchaCodeErrror, failedAttempts, cookieNameCsid } from "../Constant/Constants";
import { errorsCode, incorrectData, attemptsOvercome, errosCodeSecurity, cautionOnlyOneAttempt } from "../Constant/ConstantsOtp";
import { customerFlow } from "../Services/CustomerFlow";
import { behavioralAnalysis } from "../Services/BehavioralAnalysis";
import { validateRedirect } from "../Utilities/GeneralFunctions"

const URLAUTHORIZE = config.url.URLAUTHORIZE;
const URLGETIP = config.url.URLGETIP;
const URLVALIDATEAUTHOTP = config.url.URLVALIDATEAUTHOTP;
const URLQUERYATTEMPS = config.url.URLQUERYATTEMPS;
const maxFrontendAttempts = config.otp.OTPMAXFRONTENDATTEMPTS;

const conditions = {
  successAttemps: (_context, event) => event.data.attempts !== undefined && event.data.attempts !== null
};

const actions = {
  setDocument: assign({documentNumber: (_context, event) => event.documentNumber, documentType: (_context, event) => event.documentType}),
  setRecaptcha: assign({recapvisible: (_context, event) => event.data.attempts > 0}),
  setRecaptchaDefault: assign({recapvisible: _context => true })
};

const fetchValidateParameters = async () => {
  const ip = await fetch(URLGETIP).then(response => response.json());
  return {
    currentIp: ip,
    idClient: getUrlParam("client_id"),
    responseType: getUrlParam("response_type"),
    redirectUri: getUrlParam("redirect_uri"),
    trace: getUrlParam("trace"),
    prompt: getUrlParam("prompt"),
    linkCode: getUrlParam("linkCode"),
    businessPartner: getUrlParam("businessPartner"),
    startUrl: document.URL
  };
};

const getBodyUrlauthorize = async context => {
  return {
    data: {
      data: [
        {
          type: "validation",
          id: context.idClient,
          attributes: {
            responseType: context.responseType,
            redirectUri: context.redirectUri,
            ipConsumidor: context.currentIp.ip,
            trace: context.trace,
            prompt: context.prompt,
            linkCode: context.linkCode,
            businessPartner: context.businessPartner,
          },
        },
      ],
    },
  };
}

const authorize = async context => {
  const PARAMS = await getBodyUrlauthorize(context);
  if (context.idClient !== "" && context.idClient !== null) {
    const headersAuthorize = { contentType };
    return axios
      .post(URLAUTHORIZE, PARAMS.data, { headersAuthorize })
      .then(response => response.data);
  }
  throw new Error("clientId no especificado");
};


const getDataValidateAuthOtp = async (context, event) => {
  const { document, celphoneNumber, typeDocument, otp, captchaToken } = event;
  return {
    consumerId: context.idClient,
    localAddress: context.currentIp.ip,
    captchaToken: captchaToken,
    redirectUri: context.redirectUri,
    cellphoneNumber: celphoneNumber,
    documentType: typeDocument,
    documentNumber: document,
    otpCode: otp,
    segmentType: "",
    csid: await getValueCookie(cookieNameCsid),
    trace: context.trace,
    captchaType: context.recapvisible ? "true" : "false"
  };
}

const validateAuthOtp = async (context, event) => {
  const request = await getDataValidateAuthOtp(context, event);
  const headersValidateAuthOtp = { contentType };
  return axios
    .post(URLVALIDATEAUTHOTP, request, { headersValidateAuthOtp })
    .then(response => response.data);
};

const checkRecaptcha = async context => {
  const request = await getBodyValidateRecaptcha(context);
  const headersValidateAuthOtp = { contentType };
    return axios
    .post(URLQUERYATTEMPS, request, { headersValidateAuthOtp })
    .then(response => response.data);
};

const getBodyValidateRecaptcha = async context => {
  return {
    data:[
      {
        trace: context.trace,
        username: "",
        documentType: context.documentType,
        documentNumber: context.documentNumber
      }
    ]
  }
}

const initialContext = {
  status: "",
  error: "",
  currentIp: "",
  idClient: "",
  responseType: "",
  redirectUri: "",
  modulus: "",
  exponent: "",
  trace: "",
  errorCode: "",
  prompt: "",
  linkCode: "",
  businessPartner: "",
  startUrl: "",
  deviceType: "",
  attemps: 0,
  timeFailedAttempts: "",
  recapvisible: true,
  documentNumber: "",
  documentType: ""
};

const appAtHandMachine = createMachine(
  {
    id: "appAtHand",
    initial: "validateRedirect",
    context: initialContext,
    states: {
      validateRedirect: {
        invoke: {
          id: "validateRedirectId",
          src: validateRedirect,
          onDone: [
            {
              cond: (_context, event) => event.data.redirectUrl,
              actions: assign({ redirectUrl: (_context, event) => event.data.redirectUrl }),
              target: "redirect",
            },
            {
              target: "validateParameters",
            },
          ],
          onError: {
            target: "errorForm",
          },
        },
      },
      validateParameters: {
        invoke: {
          id: "validateParametersId",
          src: fetchValidateParameters,
          onDone: {
            target: "validateUrl",
            actions: assign({
              currentIp: (_context, event) => event.data.currentIp,
              idClient: (_context, event) => event.data.idClient,
              responseType: (_context, event) => event.data.responseType,
              redirectUri: (_context, event) => event.data.redirectUri,
              trace: (_context, event) => event.data.trace,
              prompt: (_context, event) => event.data.prompt,
              linkCode: (_context, event) => event.data.linkCode,
              businessPartner: (_context, event) => event.data.businessPartner,
              startUrl: (_context, event) => event.data.startUrl,
            }),
          },
          onError: {
            target: "errorForm",
          },
        },
      },
      validateUrl: {
        invoke: {
          id: "validateUrlId",
          src: authorize,
          onError: {
            target: "errorForm",
            actions: assign({
              error: (_context, event) => event.data,
            }),
          },
          onDone: [
            {
              target: "errorForm",
              cond: (_context, event) => event.data.errors,
              actions: assign({ errorCode: (_context, event) => event.data.errors[0].code }),
            },
            {
              target: ["validateOtp"],
              actions: assign({
                status: (_context, event) => event.data,
                modulus: (_context, event) => event.data.data[0].attributes.modulus,
              }),
            },
          ],
        },
      },
      validateOtp: {
        initial: "validateFlow",
        states: {
          validateFlow: {
              on: {
                VALIDATERECAPTCHA: { target: "validateRecaptcha",  actions: actions.setDocument }
              },
          },
          validateRecaptcha: {
            invoke: {
              id: "validateRecaptchaId",
              src: checkRecaptcha,
              onDone: [
                { cond: conditions.successAttemps, target: "continueFlow", actions: actions.setRecaptcha },
                { target: "continueFlow", actions: actions.setRecaptchaDefault }
              ],
              onError: {  target: "continueFlow", actions: actions.setRecaptchaDefault  }
            },
          },
          continueFlow: {
            after: {
              1: {  target: "validate"  }
            },
          },
          validate: {
            on: {
              VALIDATEOTP: [
                {
                  target: "authValidateOtp",
                  cond: context => context.attemps <= maxFrontendAttempts,
                },
                { target: "maxFrontendAttempts" },
              ],
              VALIDATERECAPTCHA: [
                { target: "validateRecaptcha", actions: actions.setDocument }
              ],
            },
          },
          authValidateOtp: {
            invoke: {
              id: "validateAuthOtp",
              src: validateAuthOtp,
              onDone: [
                {
                  cond: (_context, event) => event.data.errors && event.data.errors[0].code === incorrectData,
                  target: "errorIncorrectData",
                  actions: assign({ errorCode: (_context, event) => event.data.errors[0].code })
                },
                {
                  cond: (_context, event) => event.data.errors && event.data.errors[0].code === cautionOnlyOneAttempt,
                  target: "cautionOnlyOneAttempt",
                  actions: assign({ errorCode: (_context, event) => event.data.errors[0].code })
                },
                {
                  cond: (_context, event) => event.data.errors && event.data.errors[0].code === attemptsOvercome,
                  target: "errorAttemptsOvercome",
                  actions: assign({ errorCode: (_context, event) => event.data.errors[0].code })
                },
                {
                  cond: (_context, event) => event.data.errors && errosCodeSecurity.indexOf(event.data.errors[0].code) !== MINUS_ONE,
                  target: "errorCodeSecurity",
                  actions: assign({ errorCode: (_context, event) => event.data.errors[0].code })
                },
                {
                  cond: (_context, event) => event.data.errors && errorsCode.indexOf(event.data.errors[0].code) !== MINUS_ONE,
                  target: "errorAuthValidateOtp",
                  actions: assign({ errorCode: (_context, event) => event.data.errors[0].code })
                },
                {
                  cond: (_context, event) => event.data.errors && event.data.errors[0].code === unknowErrror,
                  target: "unknowErrror",
                  actions: assign({ errorCode: (_context, event) => event.data.errors[0].code })
                },
                {
                  cond: (_context, event) => event.data.errors && event.data.errors[0].code === scoreRecaptchaCodeErrror,
                  target: "errorScore",
                  actions: assign({ errorCode: (_context, event) => event.data.errors[0].code })
                },
                {
                  cond: (_context, event) => event.data.errors && event.data.errors[0].code === failedAttempts,
                  target: "failedAttempts",
                  actions: assign({
                    auth: (_context, event) => event.data.errors[0].code,
                    timeFailedAttempts: (_context, event) => event.data.errors[0].title,
                  }),
                },
                {
                  cond: (_context, event) => event.data.errors && UNAVAILABILITY_CODES_ERROS.indexOf(event.data.errors[0].code) !== MINUS_ONE,
                  target: "error",
                  actions: assign({ errorCode: (_context, event) => event.data.errors[0].code })
                },
                {
                  cond: (_context, event) => event.data.errors,
                  target: "error",
                  actions: assign({ errorCode: (_context, event) => event.data.errors[0].code })
                },
                {
                  target: "success",
                  actions: ["redirectUrlAction"],
                }
              ],
              onError: [
                {
                  target: "error",
                  actions: assign({ errorCode: (_context, event) => event.data }),
                }
              ],
            },
          },
          redirect: {
            type: "final",
            entry: ["redirectToTarget"],
          },
          success: {
            after: {
              2000: {
                target: "redirect",
                type: "final",
              },
            },
          },
          errorIncorrectData: {
            entry: assign({
              attemps: context => context.attemps + 1,
            }),
            on: {
              RETRYAUTH: { target: "validateRecaptcha",  actions: actions.setDocument }
            },
            type: "final",
          },
          errorAuthValidateOtp: {
            entry: assign({
              attemps: context => context.attemps + 1,
            }),
            on: {
              RETRYAUTH: { target: "validateRecaptcha",  actions: actions.setDocument }
            },
            type: "final",
          },
          errorAttemptsOvercome: {
            entry: assign({
              attemps: context => context.attemps + 1,
            }),
            on: {
              RETRYAUTH: { target: "validateRecaptcha", actions: actions.setDocument }
            },
            type: "final",
          },
          errorCodeSecurity: {
            entry: assign({
              attemps: context => context.attemps + 1,
            }),
            on: {
              RETRYAUTH: { target: "validateRecaptcha",  actions: actions.setDocument }
            },
            type: "final",
          },
          cautionOnlyOneAttempt: {
            on: {
              RETRYAUTH: { target: "validateRecaptcha",  actions: actions.setDocument }
            },
            type: "final"
          },
          maxFrontendAttempts: {
            on: {
              RETRYAUTH: [
                {
                  target: "validateRecaptcha",
                  actions: assign({
                    documentNumber: (_context, event) => event.documentNumber,
                    documentType: (_context, event) => event.documentType
                  }),
                }
              ]
            },
            type: "final",
          },
          error: {
            type: "final",
          },
          errorScore: {
            on: {
              RETRYAUTH: { target: "validateRecaptcha",  actions: actions.setDocument }
            },
            type: "final",
          },
          unknowErrror: {
            type: "final",
          },
          failedAttempts: {
            type: "final",
          },
        }
      },
      redirect: {
        type: "final",
        entry: ["redirectToTarget"],
      },
      errorForm: {
        type: "final",
      },
    },
  },
  {
    actions: {
      redirectUrlAction: assign((_context, event) => {
        const { url } = event.data;
        customerFlow(_context, "0003", "FORM_SECURITY_CODE_03")
        behavioralAnalysis(_context, url)
        const urlRedirect = _context.trace !== null && _context.trace !== "" ? `${url}&trace=${_context.trace}` : url
        return {
          redirectUri: urlRedirect,
        };
      }),
      redirectToTarget: _context => window.location.replace(_context.redirectUri),
    },
  }
);

export default appAtHandMachine;
