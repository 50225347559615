import AlertMessage from "../../Components/AlertMessage";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { flowNameAuthOtp } from "../../Constant/ConstantsDataLayer"

export function HandlerAppAtHandForm({ machine, showAlert, close, title, message}) {
    const isMatchAlert = ["validateOtp.errorIncorrectData", "validateOtp.errorCodeSecurity", "validateOtp.cautionOnlyOneAttempt"]
    const isMatchForm = ["validateOtp.continueFlow"]
    const [isDataIncorrect, setIsDataIncorrect] = useState(false);
    const history = useHistory();

    const handleMatchForm = () => {
        history.push("/securityCodeForm");
    };

    const handleMatchCredentialsError = () => {
        if (showAlert) {
            setIsDataIncorrect(true);
        } else {
            setIsDataIncorrect(false);
        }
    };
    
    useEffect(() => {
        if (isMatchForm.some(machine.matches)) {
            handleMatchForm();
        } else if (isMatchAlert.some(machine.matches)) {
            handleMatchCredentialsError();
        }
      }, [machine, showAlert]);

    return (
        <>
           {isDataIncorrect  && (
            <AlertMessage close={close} title={title} message={message} flowName={flowNameAuthOtp}/>
            )}
        </>
    )
}