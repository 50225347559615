import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useMachineContext } from "../Context/contextMachine";
import errorDynamicKey from "../Images/FUA-ErrorDynamicKey.svg";
import logoBanco from "../Images/IconBancolombia.svg"
import { redirectionUrlHomeBank, TU360, redirectionUrlErrorTu360 } from "../Constant/Constants";
import { errorsDynamicKey, codeDynamicKeyRedirectScreenFua } from "../Constant/ConstantsDynamicKey";

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  font-family: "Open Sans";
  font-size: 15px;
  color: #454648;
  position: relative;
  top: 10px;
  bottom: 50px;

  @media screen and (min-width: 280px) and (max-width: 480px) {
    width: 90%;
    height: auto;
    z-index: 100;
  }

  @media screen and (min-width: 481px) and (max-width: 961px) {
    width: 90%;
    height: auto;
    z-index: 100;
  }

  @media screen and (min-width: 961px) and (max-width: 1024px) {
    width: 90%;
    height: auto;
    z-index: 100;
  }

`;
const Title = styled.div`
  display: flex;
  font-size: 24px;
  font-weight: bold;
  color: #000000;
  font-family: "Nunito";
  text-align: center;
  margin-top: 15px;
  margin-bottom: 15px;
  position: relative;

  @media screen and (min-width: 280px) and (max-width: 480px) {
    position: relative;
    margin-left: 30px;
    margin-right: 0px;
    font-size: 20px;
  }

  @media screen and (min-width: 481px) and (max-width: 961px) {
    position: relative;
    margin-left: 45px;
    margin-right: 0px;
    font-size: 20px;
  }

  @media screen and (min-width: 320px) and (max-width: 1270px) and (orientation: landscape) and (min-aspect-ratio: 13/9) {
    position: relative;
    margin-left: 45px;
    margin-right: 0px;
    font-size: 20px;
  }

  @media screen and (min-width: 1271px) and (max-width: 1366px) and (orientation: landscape) and (min-aspect-ratio: 13/9) {
    position: relative;
    margin-left: 0px;
    margin-right: 0px;
    font-size: 20px;
  }
`;

const Description = styled.div`
  position:aboslute;
  display: flex;
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.3px;
  line-height: 22px;
  text-align: center;
  margin-bottom: 25px;
  position: relative;
  color: #2C2A29;
  width: auto;
  height: auto;
  margin-left: 10px;
  margin-right: 10px;
  justify-content: center;

  @media screen and (min-width: 280px) and (max-width: 480px) {
    position: relative;
    margin-left: 30px;
    margin-right: 0px;
    font-size: 14px;
  }

  @media screen and (min-width: 481px) and (max-width: 961px) {
    position: relative;
    margin-left: 45px;
    margin-right: 0px;
    font-size: 14px;
  }

  @media screen and (min-width: 320px) and (max-width: 1270px) and (orientation: landscape) and (min-aspect-ratio: 13/9) {
    position: relative;
    margin-left: 45px;
    margin-right: 0px;
    font-size: 14px;
  }

  @media screen and (min-width: 1271px) and (max-width: 1366px) and (orientation: landscape) and (min-aspect-ratio: 13/9) {
    position: relative;
    margin-left: 0px;
    margin-right: 0px;
    font-size: 14px;
  }
`;
const ImageLogo = styled.div`
  background-image: url(${logoBanco});
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  width: 100%;
  height: 52px;
  margin-top: 20px;
  margin-bottom: 30px;
`;

const Image = styled.div`
  background-image: url(${errorDynamicKey});
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  width: 160px;
  height: 220px;
  margin: auto;
`;

const Button = styled.button`
  background: #fdda24;
  color: "#292929";
  border: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 250px;
  height: 38px;
  position: relative;
  border-radius: 100px;
  font-family: Nunito;
  font-size: 18px;
  outline: none;
  top: 5px;

  @media screen and (min-width: 280px) and (max-width: 480px) {
    position: relative;
    margin-left: 40px;
    margin-right: 0px;
    font-size: 14px;
  }

  @media screen and (min-width: 481px) and (max-width: 961px) {
    position: relative;
    margin-left: 45px;
    margin-right: 0px;
    font-size: 14px;
  }

  @media screen and (min-width: 320px) and (max-width: 1366px) and (orientation: landscape) and (min-aspect-ratio: 13/9) {
    position: relative;
    margin-left: 45px;
    margin-right: 0px;
    font-size: 14px;
  }

  @media screen and (min-width: 1271px) and (max-width: 1366px) and (orientation: landscape) and (min-aspect-ratio: 13/9) {
    position: relative;
    margin-left: 0px;
    margin-right: 0px;
    font-size: 14px;
  }
`;

const CodeError = styled.div`
position: relative;
width: 250px;
height: 24px;
font-family: 'Open Sans';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 24px;
text-align: center;
letter-spacing: -0.003em;
color: #595959;
margin-bottom: 30px;

@media screen and (min-width: 280px) and (max-width: 480px) {
  position: relative;
  margin-left: 30px;
  margin-right: 0px;
  font-size: 14px;
}

@media screen and (min-width: 481px) and (max-width: 961px) {
  position: relative;
  margin-left: 45px;
  margin-right: 0px;
  font-size: 14px;
}

@media screen and (min-width: 320px) and (max-width: 1270px) and (orientation: landscape) and (min-aspect-ratio: 13/9) {
  position: relative;
  margin-left: 45px;
  margin-right: 0px;
  font-size: 14px;
}

@media screen and (min-width: 1271px) and (max-width: 1366px) and (orientation: landscape) and (min-aspect-ratio: 13/9) {
  position: relative;
  margin-left: 0px;
  margin-right: 0px;
  font-size: 14px;
}
`;

function ErrorDynamicKey({ codeError }) {
  const [machine] = useMachineContext();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [codeErrorDc, setCode] = useState("");
  const [redirectScreenFua, setRedirectScreenFua] = useState(false);

  useEffect(() => {
    errorsDynamicKey.forEach(errorDynamic => {
      if (codeError === errorDynamic.code) {
        setTitle(errorDynamic.title)
        setDescription(errorDynamic.description)
        setCode("Código " + errorDynamic.code)
      }
    })

    if (codeDynamicKeyRedirectScreenFua.includes(codeError)) {
      setRedirectScreenFua(true)
    }
  }, []);

  const redirectionUri = e => {
    e.preventDefault();
    if (machine.context.idClient === TU360) {
      window.location.replace(redirectionUrlErrorTu360)
    }
    redirectScreenFua ? window.location.replace(machine.context.startUrl) : window.location.replace(redirectionUrlHomeBank)
  };

  return (
    <Container>
      <ImageLogo />
      <Image />
      <Title>{title}</Title>
      <Description>{description}</Description>
      <CodeError>{codeErrorDc}</CodeError>
      <Button onClick={redirectionUri}>INTENTALO MÁS TARDE</Button>
    </Container>
  );
}
export default ErrorDynamicKey;
