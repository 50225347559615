import React from "react";
import { StyledHeader, BackContainer, BackIcon, BackText, Logo, ExitContainer, ExitText, ExitIcon, Container, ContainerV1 } from "./HeaderTemplateStyle";

function HeaderTemplate({isV2, exitFunction, backFunction, logoHeader, marginBottom, marginBottomMobile, isHide }) {
  return (
    <StyledHeader mb={marginBottom} mbm={marginBottomMobile} hide={isHide}>
          {isV2 && (
            <Container>
              <BackContainer onClick={backFunction}>
                <BackIcon />
                <BackText>{"Volver"}</BackText>
              </BackContainer>
              <Logo img={logoHeader} />
              <ExitContainer onClick={exitFunction}>
                <ExitText >{"Salir"}</ExitText>
                <ExitIcon />
              </ExitContainer>
            </Container>
          )}
          {!isV2 && (
            <ContainerV1>
              <Logo img={logoHeader} />
            </ContainerV1>
          )}
    </StyledHeader>
  );
}

export default HeaderTemplate;
