import styled from "styled-components";

export const Container = styled.div`
  border-radius: 16px;
  padding: 23px 39px 28px 39px;
  width: 556px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;

  @media screen and (min-width: 280px) and (max-width: 480px) {
    width: 100vw;
  }

  @media screen and (min-width: 320px) and (max-width: 850px) and (orientation: landscape) and (min-aspect-ratio: 13/9) {
    width: 50vw;
  }

  @media screen and (width: 320px) and (height: 568px) {
    width: 97vw;
  }

  @media screen and (width: 568px) and (height: 320px) and (orientation: landscape) {
    width: 70vw;
  }

  @media screen and (width: 600px) and (height: 960px) and (orientation: portrait) {
    width: 80vw;
  }

  @media screen and (width: 1280px) and (height: 800px) and (orientation: landscape) {
    width: 35vw;
  }

  @media screen and (width: 883px) and (height: 412px) and (orientation: landscape) {
    width: 50vw;
  }
`;
