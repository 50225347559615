import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import StepInstructionInformation from "../Images/StepInstructionInformation.svg"
import ButtonTemplate from "./ButtonTemplate/ButtonTemplate";

const Container = styled.div`
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Content = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  font-family: "Open Sans";
  font-size: 15px;
  color: #454648;
`;
const Title = styled.div`
  display: flex;
  position: relative;
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 26px;
  letter-spacing: -0.0045em;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 30px;
`;
const ContainerItems = styled.div`
  display: flex;
  position: relative;
  align-items: left;
  flex-direction: column;
  justify-content: space-between;
`;
const Items = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
`;
const Images = styled.div`
  background-image: url(${StepInstructionInformation});
  background-repeat: no-repeat;
  background-position: center;
  width: 350px;
  height: 360px;
`;
const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 10px;
`;
const Line = styled.hr`
  width: 100%;
  border: 1px solid #CCCCCC;
  background: black;

  @media screen and (min-width: 280px) and (max-width: 480px) {
    width: 90%;
  }

  @media screen and (min-width: 481px) and (max-width: 961px) {
    width: 90%;
  }
`;

export const ButtonEnableStyle = `
  background: #FDDA24;
  border: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 342px;
  height: 55px;
  position: relative;
  border-radius: 100px;
  font-family: "Open Sans";
  font-weight: 600;
  font-size: 16px;
  outline: none;
  padding: 12px 35px 12px 35px;
  gap: 10px;
  cursor: pointer;
  color: #2C2A29;
`;


function InstructionInformation({ hide }) {
  return (
    <Container>
      <Content>
        <Title>¿Cómo generar el código?</Title>
        <ContainerItems>
          <Items> <Images /> </Items>
          <Line></Line>
        </ContainerItems>
        
        <ButtonGroup>
          <ButtonTemplate
            sizeHeightM="69px"
            customStyle={ButtonEnableStyle}
            type="Button"
            disabled={false}
            fuctionButton={hide}  
            title={"Entendido"}
          >
          </ButtonTemplate>
        </ButtonGroup>
      </Content>
    </Container>
  );
}

InstructionInformation.propTypes = {
  hide: PropTypes.func.isRequired,
};
export default InstructionInformation;
