import React from "react";
import styled from "styled-components";

const StyleInputPassword = styled.input`
  border-radius: 0px !important;
  width: 20px;
  height: 20px;
  text-align: center;
  font-size: 20px;
  padding: 10px;
  outline: none;
  border: none;
  border-bottom: 1px solid #808285;
  margin-left: 5px;
  margin-right: 5px;
  pointer-events: ${props => props.pointer};

  &:focus{
    border-bottom: 1px solid #ffc20e;
  }

  ${props => {
    if (props.error === undefined) {
      return `border-bottom: 2px solid #808285;`;
    }
  }}

  @media screen and (width: 320px) and (height: 568px) and (orientation: portrait){
    width: 7px;
    font-size: 15px;
  }

  @media screen and (width: 280px) and (height: 653px) and (orientation: portrait){
    width: 7px;
    font-size: 15px;
  }
`;

function PasswordInput({
    fieldRef,
    dataTestid,
    type,
    name,
    error,
    maxLength,
    value,
    handleChange,
    handleBack,
    handleFocus,
    handleClick,
    onSelectHandle,
}) {
    return (
        <>
            <StyleInputPassword
                ref={fieldRef}
                data-testid={dataTestid}
                type={type}
                name={name}
                error={error}
                maxLength={maxLength}
                value={value}
                onChange={handleChange}
                onKeyDown={handleBack}
                onFocus={handleFocus}
                onClick={handleClick}
                onSelect={onSelectHandle}
            />
        </>
    );
}

export default PasswordInput;
