import styled from "styled-components";

const Container = styled.div`
  padding: 5px 48px 48px 48px;
  border-radius: 16px;
  width: 556px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;

  @media screen and (min-width: 280px) and (max-width: 480px) {
    width: 90vw;
  }

  @media screen and (min-width: 320px) and (max-width: 850px) and (orientation: landscape) and (min-aspect-ratio: 13/9) {
    width: 60vw;
  }

  @media screen and (width: 320px) and (height: 568px) {
    width: 107vw;
  }

  @media screen and (width: 568px) and (height: 320px) and (orientation: landscape) {
    width: 70vw;
  }

  @media screen and (width: 600px) and (height: 960px) and (orientation: portrait) {
    width: 80vw;
  }

  @media screen and (width: 1280px) and (height: 800px) and (orientation: landscape) {
    width: 35vw;
  }

  @media screen and (width: 883px) and (height: 412px) and (orientation: landscape) {
    width: 50vw;
  }
`;
export default Container;
