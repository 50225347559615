import styled from "styled-components";

export const GroupWhichOptionDescription = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: auto;
  cursor: pointer;

  /*@media screen and (min-width: 280px) and (max-width: 390px) {
    width: 352px;
  }*/
`;