import styled from "styled-components";

export const Title = styled.div`
 display: flex;
 justify-content: center;
 text-align: center;
 font-family: "Open Sans";
 font-weight: 600;
 font-size: 28px;
 color: rgba(44, 42, 41, 1);
 line-height: 32px;
 @media screen and (min-width: 280px) and (max-width: 500px) {
  font-size: 19.78px;
  line-height: 25.71px;
}
`;


export const SubTitle = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 0 auto 30px auto;
  width: 358px;
  height: 22px;
  font-family: 'Open Sans';
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgba(44, 42, 41, 1);

  @media screen and (min-width: 280px) and (max-width: 500px) {
    font-size: 15.82px;
    line-height: 23.73px;
  }
`;
