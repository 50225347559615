import ModalErrorTemplate from "../ModalErrorTemplate/ModalErrorTemplate"
import alertIcon from "../../Images/alertIcon.svg"
import {ButtonGroupPopUpGoOut} from "../CustomButtons/ButtonGroupPopUpGoOut/ButtonGroupPopUpGoOut"

export function AlertGoOut({ machine, setShowPopUpGoOut, isButtonBack, isV2 }) {
    const hidePopUp = () => {
        setShowPopUpGoOut(false)
    };

    return (
        <ModalErrorTemplate additionalButtons={<ButtonGroupPopUpGoOut setShowPopUpGoOut={setShowPopUpGoOut} machine={machine} isButtonBack={isButtonBack} isV2={isV2}></ButtonGroupPopUpGoOut>} heightM={"280px"} widthM={"400px"} fuctionButtonClose={hidePopUp} isShowCloseButton={true} iconImageSvg={alertIcon} title={"¿Deseas salir?"} description={"No quedarán guardados los datos ingresados."} showAdditionalbotones={true} setShowPopUpGoOut={setShowPopUpGoOut} machine={machine} heightIcon={"48px"}></ModalErrorTemplate>
    );
}