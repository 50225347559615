import ButtonTemplate from "../../ButtonTemplate/ButtonTemplate";
import {
    ButtonEnableStyle,
    ButtonDisableStyle,
    ButtonBackStyle,
} from "../../ButtonTemplate/ButtonTemplateStyle";
import { ButtonGroup } from "../../../Styles/PasswordFormStyle/ButtonGroup";
import { back } from "../../../Utilities/GeneralFunctions"

export function ButtonGroupSecurityCodeForm({ conditionReCaptchaToken, validateForm }) {
    return (
        <ButtonGroup>
            <ButtonTemplate
                customStyle={ButtonBackStyle}
                type="button"
                title={"Volver"}
                fuctionButton={back}
            />
            <ButtonTemplate
                customStyle={
                    conditionReCaptchaToken ? ButtonEnableStyle : ButtonDisableStyle
                }
                type="submit"
                fuctionButton={validateForm}
                title={"Continuar"}
                disabled={conditionReCaptchaToken ? false : true}
            />
        </ButtonGroup>
    );
}
