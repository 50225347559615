import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Field } from "formik";

const filteredPropsInput = ({ className, valid, error, ...props }) => (
  <Field className={className} {...props} />
);
filteredPropsInput.propTypes = {
  className: PropTypes.string.isRequired,
};

const configurationStyle = {
  pixel : "1px",
  pixelValid : "2px",
  border : "solid",
  colorValid : "#2C2A29",
  colorSelected : "#ffc20e",
  colorError :"red",
  noColor : "",
  borderBottom : "border-bottom :",
  selectedFont : "font-weight: 400",
  validFont : "font-weight: bold;"
}

const validBorder = `border-bottom: ${configurationStyle.pixelValid} ${configurationStyle.border} ${configurationStyle.colorValid};${configurationStyle.validFont}`
const errorBorder = `${configurationStyle.pixel} ${configurationStyle.border} ${configurationStyle.colorError};`
const selectedBorder = `border-bottom: ${configurationStyle.pixel} ${configurationStyle.border} ${configurationStyle.colorSelected};${configurationStyle.selectedFont}`

const InputContainer = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: ${props => props.top};
  position: relative;
  @media screen and (min-width: 320px) and (max-width: 850px) and (orientation: landscape) and (min-aspect-ratio: 13/9) {
    height: 60px;
  }
`;
const Label = styled.label`
  color: #2C2A29;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 0px;
  top: 25px;
  left: 30px;
  transition: all 0.2s ease;
  position: absolute;
  flex: none;
  order: 1;
  flex-grow: 0;

  @media screen and (min-width: 320px) and (max-width: 850px) and (orientation: landscape) and (min-aspect-ratio: 13/9) {
    top: 45px;
  }
`;
const StyledInput = styled(filteredPropsInput)`
  border: 0;
  border-radius: 0px !important;
  color: #292929;
  font-family: "Open Sans";
  letter-spacing: -0.3px;
  line-height: 24px;
  border-bottom: 1px solid #808285;
  background-color: transparent;
  outline: none;
  padding: 12px 3px 5px 30px;
  font-size: 16px;
  transition: all 0.2s ease;
  position: relative;
  z-index: 510;

  &:not(:placeholder-shown) + ${Label} {
    transform: translateY(-30px) translateX(-40px);
    z-index: 501;
    background: transparent;
    padding: 0 8px;
  }

  &:focus + ${Label} {
    transform: translateY(-30px) translateX(-40px);
    z-index: 501;
    background: transparent;
    padding: 0 8px;
  }

  &:focus{
    ${selectedBorder};
    ${props => {
      if (!props.valid) {
        return `${configurationStyle.borderBottom} ${props.error ? errorBorder : configurationStyle.noColor};`;
      }
      return undefined
    }}
  }

  ${props => {
    if (props.valid) {
      return validBorder;
    }
    return `${configurationStyle.borderBottom} ${props.error ? errorBorder : configurationStyle.noColor};`;
  }}
 
  @media screen and (min-width: 320px) and (max-width: 850px) and (orientation: landscape) and (min-aspect-ratio: 13/9) {
    top: 20px;
  }
`;
const StyledIcon = styled.div`
  background-image: url(${props => props.icon});
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  width: 21px !important;
  height: 24px;
  top: 12px;
  text-align: center;

  @media screen and (min-width: 320px) and (max-width: 850px) and (orientation: landscape) and (min-aspect-ratio: 13/9) {
    top: 35px;
  }
`;

function Input({
  type,
  name,
  autoCorrect,
  autoComplete,
  valid,
  error,
  label,
  placeholder,
  top,
  value,
  disabled,
  icon
}) {
  return (
    <>
      <InputContainer top={top}>
        <StyledIcon icon={icon}/>
        <StyledInput
          type={type}
          name={name}
          autoCorrect={autoCorrect}
          autoComplete={autoComplete}
          valid={valid}
          error={error}
          placeholder={placeholder}
          value={value}
          disabled={disabled}
        />
        <Label>{label}</Label>
      </InputContainer>
    </>
  );
}
Input.propTypes = {
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  autoCorrect: PropTypes.string.isRequired,
  autoComplete: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  top: PropTypes.string.isRequired,
};
export default Input;