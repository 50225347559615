import styled from "styled-components";

export const Subtitle = styled.div`
  font-family: "Open Sans";
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.17px;
  color: rgba(44, 42, 41, 1);

  @media screen and (min-width: 0px) and (max-width: 575px) {
    font-size: 13.84px;
    line-height: 19.78px;
  }
`;
