import React, { useState, useEffect, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha-enterprise";
import { useMachineContext } from "../../Context/contextMachine";
import { UseFua } from "../../Context";
import { useHistory } from "react-router-dom";
import Container from "../Container";
import ContainerHeader from "../ContainerHeader";
import PrincipalContainer from "../PrincipalContainer";
import Footer from "../Footer";
import MainContent from "../MainContent";
import OtpInputDynamicKey from "../OtpInputDynamicKey";
import Modal from "../Modal";
import ErrorDynamicKey from "../ErrorDynamicKey"
import { EFA002 } from "../../Constant/Constants";
import { errorsDynamicKey, codeDynamicKeyIncorrect, timeExpirationDynamicKey, listCodeErroDynamicKey } from "../../Constant/ConstantsDynamicKey";
import { useIdleTimer } from "react-idle-timer";
import TitleHeader from "../TitleHeader";
import { FormGroup } from "../../Styles/PasswordFormStyle/FormGroup"
import { ErrorFront } from "../../Styles/DynamicKeyFormStyle/ErrorFront"
import { ContainerRecaptcha } from "../../Styles/PasswordFormStyle/ContainerRecaptcha"
import config from "../../config";
import { customerFlow } from "../../Services/CustomerFlow";
import { getTrace } from '../../Utilities/httpUtils'
import { AlertGoOut } from "../AlertGoOut/AlertGoOut"
import { ButtonGroupUserDynamickKey } from "../CustomButtons/ButtonGroupUserDynamickKey/ButtonGroupUserDynamickKey"
import { HandlerUserDynamicKey } from "../../HandlerComponents/HandlerDynamicKeyFirstFactor/HandlerUserDynamicKey"
import HeaderTemplate from "../HeaderTemplate/HeaderTemplate"
import logoHeader from "../../Images/headerIconBancolombia.svg";
import { contextSwitchHandlerBiocatch, back } from "../../Utilities/GeneralFunctions"
import {loadFormPasswordUserDynamicKey, clickContinueFormPasswordUserDynamicKey} from "../../HandlerComponents/HandlerDatalayers/HandlerDatalayers"

window.recaptchaOptions = {
    useRecaptchaNet: true,
    removeOnMount: false,
};

export function AuthDynamicKeyForm() {
    const history = useHistory();
    const { username, deviceType } = UseFua();
    const [machine, send] = useMachineContext();
    const [error, setError] = useState("");
    const [activeButton, setActiveButton] = useState(false);
    const [values, setvalues] = useState();
    const [showAlert, setShowAlert] = useState(false);
    const [customMessage, setMessage] = useState("");
    const [customTitle, setTitle] = useState("");
    const [incorrectDynamicKey, setIncorrectDynamicKey] = useState(false)
    const recaptchaRef = useRef();
    const isRecaptchaActive = config.recaptcha.RECAPTCHA_CD_STATUS === "true" && machine.machine.id === "authDynamicKey" ? "true" : "false";
    const [captchaToken, setCaptchaToken] = useState(null);
    const isMatchErrorDynamicKey = ["validateDynamicKey.errorGeneric", "validateUserDynamicKey.generalError"]
    const [showPopUpGoOut, setShowPopUpGoOut] = useState(false);
    const conditionReCaptchaToken = captchaToken !== null && activeButton;
    const isV2 = window.document.URL.includes("v2") || window.document.URL.includes("dynamickeyform")

    useEffect(() => {
        contextSwitchHandlerBiocatch("DYNAMIC_KEY_FORM")
        loadFormPasswordUserDynamicKey()
    }, []);

    useEffect(() => {
        customerFlow(machine.context, "0002", "FORM_DYNAMIC_KEY_02")
    }, []);

    useEffect(() => {
        if (isRecaptchaActive === "false") {
            handledSubmit("");
        } else {
            recaptchaRef.current.reset()
        }

        if (history.action === "PUSH") {
            send("RETRYAUTH");
        }
    }, [isRecaptchaActive, history]);

    const handledSubmit = value => setCaptchaToken(value)

    useEffect(() => {
        if (listCodeErroDynamicKey.includes(machine.context.errorCode)) {
            if (codeDynamicKeyIncorrect.includes(machine.context.errorCode)) {
                setShowAlert(true)
            }
            errorsDynamicKey.forEach(element => {
                if (element.code === machine.context.errorCode) {
                    setTitle(element.title)
                    setMessage(element.description)
                }
            });
        }
    }, [machine]);

    const validateForm = async () => {
        send("RETRYAUTH", { values, captchaToken, username, deviceType });
        send("VALIDATEDYNAMICKEY", { values, captchaToken, username, deviceType });
        clickContinueFormPasswordUserDynamicKey()
    };

    const handleOnIdle = () => {
        window.location.replace(machine.context.redirectUri + EFA002 + getTrace(machine.context));
    };

    useIdleTimer({
        timeout: timeExpirationDynamicKey,
        onIdle: handleOnIdle,
        debounce: 500,
    });

    const showPopUp = () => {
        setShowPopUpGoOut(true)
    };

    return (
        <PrincipalContainer>
            <HeaderTemplate logoHeader={logoHeader} isV2={isV2} exitFunction={showPopUp} backFunction={back} marginBottom='45px' marginBottomMobile='45px' />
            {showPopUpGoOut && <AlertGoOut machine={machine} setShowPopUpGoOut={setShowPopUpGoOut} isButtonBack={false} isV2={isV2} />}
            <MainContent>
                <TitleHeader title="Clave dinámica" widthTitle="454px" heightTitle="30px" topTittle="160px" />
                <Container height="232px" top="30%">
                    <ContainerHeader
                        icon
                        heightL="10%"
                        subTitle={"Genérala desde tu App Bancolombia o ingresa el código de seguridad que hemos enviado como SMS."}
                    />
                    <FormGroup id="formGroup">
                        <OtpInputDynamicKey
                            setvalues={setvalues}
                            error={error}
                            setError={setError}
                            setActiveButton={setActiveButton}
                            setIncorrectDynamicKey={setIncorrectDynamicKey}
                            incorrectDynamicKey={incorrectDynamicKey}
                        />
                        {error && <ErrorFront error={error}>{error}</ErrorFront>}
                        {isRecaptchaActive === "true" && (
                            <ContainerRecaptcha topM="20px" display="flex">
                                <ReCAPTCHA
                                    ref={recaptchaRef}
                                    sitekey={config.recaptcha.RECAPTCHA_KEY}
                                    onChange={handledSubmit}
                                />
                            </ContainerRecaptcha>
                        )}
                        <ButtonGroupUserDynamickKey machine={machine} validateForm={validateForm} conditionReCaptchaToken={conditionReCaptchaToken}></ButtonGroupUserDynamickKey>
                    </FormGroup>
                    {(isMatchErrorDynamicKey.some(machine.matches)) && (
                        <Modal
                            isShowing
                            height="720px"
                            topM="0%"
                            top="0%"
                            buttonClose={false}
                            width="430px"
                            widthM="340px"
                        >
                            <ErrorDynamicKey codeError={machine.context.errorCode} />
                        </Modal>
                    )}
                </Container>
                <HandlerUserDynamicKey machine={machine} showAlert={showAlert} customMessage={customMessage} setShowAlert={setShowAlert} customTitle={customTitle} history={history} />
            </MainContent>
            <Footer top="0px" topMovil="100%" />
        </PrincipalContainer>
    );
}