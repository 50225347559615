export const attemptsOvercome = "ER404-19"
export const incorrectData = "ER404-08"
export const incorrectsecurityCode = "ER500-01"
export const cautionOnlyOneAttempt = "ER404-06"

export const title = "Datos Incorrectos"
export const description = "Los datos ingresados no coinciden, inténtalo nuevamente."

export const errorsCode = [
   attemptsOvercome,
   incorrectData,
   incorrectsecurityCode,
   cautionOnlyOneAttempt
]

export const errosCodeSecurity = [
   incorrectsecurityCode
]

export const errorsOtp = [
   { code: attemptsOvercome, title: "Superaste el número de intentos", description: "Intenta de nuevo en 2 horas" },
   { code: incorrectData, title: title, description: description },
   { code: incorrectsecurityCode, title: title, description: description },
   { code: cautionOnlyOneAttempt, title: title, description: description },
]
