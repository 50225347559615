import React from "react";
import { createPortal } from "react-dom";
import { Overlay, Wrapper, Header, CloseButton, Container, ImageClose, ImageError, CotainerImageError, ContainerDes, Title, Description, Button } from "../Styles/ModalError/ModalErrorStyle"
import { useHistory } from 'react-router';

function ModalError({
  isShowing,
  hide,
  children,
  heightM,
  topM,
  top,
  buttonClose,
  startUrl,
  title,
  message,
  titleBotton
}) {
  const history = useHistory();
  let showing = isShowing;
  const redirectionUri = () => {
    showing = false;
    startUrl.includes("login") ? history.push("/login" + startUrl.split("login")[1]) : window.location.replace(startUrl)
  };

  if (showing) {
    return (createPortal(
      <>
        <Overlay />
        <Wrapper aria-modal aria-hidden tabIndex={-1} role="dialog">
          <Container heightM={heightM} topM={topM} top={top}>
            <Header>
              {buttonClose && (
                <CloseButton
                  type="button"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={redirectionUri}
                >
                  <ImageClose />
                </CloseButton>
              )}
            </Header>
            <CotainerImageError>
              <ImageError />
            </CotainerImageError>
            <ContainerDes>
              <Title>{title}</Title>
              <Description>{message}</Description>
              <Button onClick={redirectionUri}>{titleBotton}</Button>
            </ContainerDes>
            {children}
          </Container>
        </Wrapper>
      </>,
      document.body
    ));
  }
  return null;
}
export default ModalError;
