import {
    USER_AGENT,
    informationScreen,
    informationNavigator,
  } from "../Constant/Constants";
  import config from "../config";
  
  export const modelAuthV1 = async params => {
    return {
      data: [
        {
          username: params.username,
          password: params.encrptedPassword,
          useragent: window.navigator.userAgent.slice(0, USER_AGENT),
          awcode: "",
          consumerId: params.context.idClient,
          device: params.deviceType,
          localaddress: params.context.currentIp.ip,
          factors: params.context.prompt,
          captchatoken: params.captchaToken,
          redirect_uri: params.context.redirectUri,
          trace: params.context.trace,
          info: {
            screen: informationScreen,
            navigator: informationNavigator,
            time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
          csid: ""
        },
        {
          url: config.url.URLAUTHFUA
        }
      ],
    };
  }
  
  export const modelAuthV2 = async params => {
    return {
      data: [
        {
          username: params.username,
          password: params.encrptedPassword,
          useragent: window.navigator.userAgent.slice(0, USER_AGENT),
          awcode: "",
          consumerId: params.context.idClient,
          device: params.deviceType,
          localaddress: params.context.currentIp.ip,
          factors: params.context.prompt,
          captchatoken: params.captchaToken,
          redirect_uri: params.context.redirectUri,
          trace: params.context.trace,
          info: {
            screen: informationScreen,
            navigator: informationNavigator,
            time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
          csid: params.csid,
          captchaType: params.context.recapvisible ? "true" : "false"
        },
        {
          url: config.url.URLAUTHFUAV2
        }
      ],
    };
  }