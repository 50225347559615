import React from "react";
import styled from "styled-components";
import Oval from "../Images/Oval.svg";

const Overlay = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(19.028px);
`;
const BackgroundSpinner = styled.div`
  background-image: url(${Oval});
  background-repeat: no-repeat;
  background-position: center;
  width: 159px;
  height: 159px;
  position: absolute;
  z-index: -1;
`;

const StyledSpinner = styled.div`
  border: 2px solid #2c2a29;
  border-radius: 50%;
  border-top: 2px solid transparent;
  width: 33px;
  height: 33px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
const Parragraph = styled.p`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;

  text-align: center;
  letter-spacing: -0.2px;
  color: #2c2a29;
`;

function RedirectionMessage() {
  return (
    <Overlay>
      <BackgroundSpinner />
      <StyledSpinner />
      <Parragraph>Redireccionando…</Parragraph>
    </Overlay>
  );
}

export default RedirectionMessage;
