export default {
  MAX_ATTACHMENT_SIZE: 5000000,
  url: {
    URLAUTHORIZE: process.env.REACT_APP_URL_AUTHORIZE,
    URLAUTHFUA: process.env.REACT_APP_URL_AUTHFUA,
    URLSSO: process.env.REACT_APP_URL_SSO,
    URLGETIP: process.env.REACT_APP_URL_GET_IP,
    URLPREPAREDYNAMICKEY: process.env.REACT_APP_URL_PREPARE_DYNAMIC_KEY,
    URLVALIDATEDYNAMICKEY: process.env.REACT_APP_URL_VALIDATE_DYNAMIC_KEY,
    URLVALIDATEAUTHOTP: process.env.REACT_APP_URL_VALIDATE_AUTH_OTP,
    URLAUTHFUADYNAMICKEY: process.env.REACT_APP_URL_AUTHENTICATE_DYNAMIC_KEY,
    URLAUTHUSERDYNAMICKEY: process.env.REACT_APP_URL_AUTHENTICATE_USER_DYNAMIC_KEY,
    URLCUSTOMERFLOW: process.env.REACT_APP_URL_CUSTOMER_FLOW,
    URLQUERYATTEMPS: process.env.REACT_APP_URL_QUERY_ATTEMPS,
    URLSDK: process.env.REACT_APP_URL_SDK,
    URLBEHAVIORALANALYSIS: process.env.REACT_APP_URL_BEHAVIORAL_ANALYSIS,
    URLAUTHFUAV2: process.env.REACT_APP_URL_AUTHFUA_V2,
  },
  recaptcha: {
    RECAPTCHA_STATUS: process.env.REACT_APP_RECAPTCHA_STATUS,
    RECAPTCHA_KEY: process.env.REACT_APP_KEY_RECAPTCHA,
    RECAPTCHA_OTP_STATUS: process.env.REACT_APP_RECAPTCHA_OTP_STATUS,
    RECAPTCHA_CD_STATUS: process.env.REACT_APP_RECAPTCHA_CD_STATUS,
    RECAPTCHA_KEY_INVISIBLE: process.env.REACT_APP_KEY_INVISIBLE_RECAPTCHA,
    RECAPTCHA_ALPHANUMERIC_KEY_STATUS: process.env.REACT_APP_RECAPTCHA_ALPHANUMERIC_KEY_STATUS,
  },
  domain: {
    ENVIRONMENT_DOMAIN: process.env.REACT_APP_ENVIRONMENT_DOMAIN
  },
  otp: {
    OTPMAXFRONTENDATTEMPTS: process.env.REACT_APP_OTP_MAX_FRONTEND_ATTEMPTS
  },
  customerFlow: {
    TIMEOUTCUSTOMERFLOW: process.env.REACT_APP_TIME_OUT_CUSTOMER_FLOW
  },
  urlAlphanumericKeyAndToken: {
    URLGETPUBLICALPHANUMERICKEY: process.env.REACT_APP_URL_GET_PUBLIC_ALPHANUMERIC_KEY,
    URLVALIDATEALPHANUMERICKEY: process.env.REACT_APP_URL_VALIDATE_ALPHANUMERIC_KEY,
    URLVALIDATETOKENSF: process.env.REACT_APP_URL_VALIDATE_TOKEN_SF,
    URLVALIDTEISMIGRATEDALPHANUMERICKEY: process.env.REACT_APP_URL_VALIDTE_IS_MIGRATED_ALPHANUMERIC_KEY,
    SVNENABLEDCLIENTS: process.env.REACT_APP_SVN_ENABLED_CLIENTS
  },
  path: {
    PASSWORDFORMPATH: process.env.REACT_APP_PASSWORD_FORM_PATH,
    PASSWORDFORMPATHV2: process.env.REACT_APP_PASSWORD_FORM_V2_PATH
  },
  pin: {
    ACTIVETIMESTAMP: process.env.REACT_APP_ACTIVE_TIMESTAMP_PIN,
  }
};
